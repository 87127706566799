//---------------------------------------------------------------------------------------------------
// ## IMPORTS ##
// Library Imports
import { useEffect, useRef, useState } from "react";
import { Params, useNavigate, useParams } from "react-router-dom";
import { TextureLoader } from "three";
// Material ui Imports
// Custom components imports (and hooks and helpers)
import {
    VTButton,
    VTCanvas,
    VTCube,
    VTHTMLHotspot,
    VTRichTextEditor,
    VTRichTextEditorContentPreview,
} from "@virtus-tech-repository/virtus-tech-repository";
import { useAppSelector } from "../../store/hooks";
import { EDraggableOptions } from "@virtus-tech-repository/virtus-tech-repository/lib/models/media.model";
import { Box, CircularProgress, Container, Grid, Skeleton, Tooltip, useTheme } from "@mui/material";
import { iImageProps } from "@virtus-tech-repository/virtus-tech-repository/lib/components/VTCube";
import { element } from "prop-types";
import {
    AddRounded,
    CancelRounded,
    DeleteRounded,
    EditRounded,
    SaveRounded,
    ArrowForwardRounded,
    Volcano,
    VolumeUpRounded,
    CloseRounded,
    VolumeOffRounded,
} from "@mui/icons-material";
import VTCreatorVideoControls from "../components/VTCreatorVideoControls";
import VTCreatorVideoTimeline from "../components/VTCreatorVideoTimeline";
import { useDispatch } from "react-redux";
import { elementRoles } from "aria-query";
import {
    setCurrentAudio,
    setCurrentFeedbackImage,
    setCurrentFeedbackVideo,
    setCurrentHotspot,
    setCurrentQuestion,
    setCurrentRichTextEditorNormalModeId,
    setCurrentSidePanel,
    setCurrentStartingLocation,
    setEditHotspotDialogTabCurrentlyOpen,
    setQuestionAudioAdd,
} from "../../store/slices/current.slice";
import VTHotspotQuickEdit from "../components/VTHotspotQuickEdit";
import ReactAudioPlayer from "react-audio-player";
import VTCreatorQuestion from "../components/VTCreatorQuestion";
import VTHotspotEditDialog from "../components/VTHotspotEditDialog";
import { Html } from "@react-three/drei";
import VTVideo from "../components/VTVideo";
import VTCreatorAudioControls from "../components/VTCreatorAudioControls";
import VTCreatorTimelineContainer from "./CreatorTimelineContainer";
import VTSphere from "../components/VTSphere";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import VTUserInterface from "../components/VTUserInterface";
import { VTHotspotHTMLWrapper } from "../components/VTHotspotHTMLWrapper";
import * as THREE from "three";
import ImmersiveModel from "../components/threeDModels.tsx/ImmersiveModel";
import VTViewerUserInterface from "../components/VTViewerUserInterface";
import ThreeDViewer from "../components/threeD/ThreeDViewer";
import Passthrough from "../components/threeD/Passthrough";
import VTSidePanelMediaDropContainer from "../components/SidePanelMediaDropContainer";
import { IDragOptions } from "@virtus-tech-repository/virtus-tech-repository/lib/models/current.model";
import {
    EDraggableHotspotId,
    EDroppableContainerId,
} from "@virtus-tech-repository/virtus-tech-repository/lib/models/DragAndDrop.models";
import {
    IScenario,
    IScene,
    IStep,
    IThreeSixtyVideoContext,
} from "@virtus-tech-repository/virtus-tech-repository/lib/models/scenario.model";
import { IHotspot } from "@virtus-tech-repository/virtus-tech-repository/lib/models/hotspot.model";
import {
    useDeleteHotspotMutation,
    useGetGroupHotspotsQuery,
    useGetHotspotsQuery,
} from "../../services/hotspot.service";
import VTVRLoadingSpinner from "../components/VTVRLoadingSpinner";
import { blankQuestionHotspot } from "../../helpers/hotspotDataGenerator";
import { useGetScenesQuery, useUpdateSceneMutation } from "../../services/scene.service";
import VTSceneSettings from "../components/VTSceneSettings";
import { DropPoint } from "@virtus-tech-repository/virtus-tech-repository/lib/components/VTCreatorDropContainer";
import DropImage from "../../assets/images/image_drop.svg";
import DropVideo from "../../assets/images/video_drop.svg";
import DropAudio from "../../assets/images/audio_drop.svg";
// Media imports

//---------------------------------------------------------------------------------------------------
// ## MUI STYLED ##
// Layout stuff remains inline, other styling pulled into it's own components

//---------------------------------------------------------------------------------------------------
// ## INTERFACES ##
// Optional variables towards the bottom
interface IProps {
    currentAttachedObject: any;
    setCurrentAttachedObject: any;
    videoCurrentState: "Paused" | "Playing" | "Question" | undefined;
    setVideoCurrentState: (videoCurrentState: "Paused" | "Playing" | "Question" | undefined) => void;
    sceneLoading: string;
    setSceneLoading: (sceneLoading: string) => void;
    createScene: any;
    updateScene: any;
    hotspotLoading: string;
    setHotspotLoading: (hotpsotLoading: string) => void;
    createHotspot: any;
    updateHotspot: any;
    deleteHotspot: any;
    createSceneLoading: any;
    setChangeSceneBuffer: any;
    videoTimelineLoading: string;
    setVideoTimelineLoading: (videoTimelineLoading: string) => void;
}

//---------------------------------------------------------------------------------------------------
// ## COMPONENTS ##
// Only display layouts in pages
export default function CreatorCanvasContainer({
    currentAttachedObject,
    setCurrentAttachedObject,
    videoCurrentState,
    setVideoCurrentState,
    sceneLoading,
    setSceneLoading,
    createScene,
    updateScene,
    hotspotLoading,
    setHotspotLoading,
    createHotspot,
    updateHotspot,
    deleteHotspot,
    createSceneLoading,
    setChangeSceneBuffer,
    videoTimelineLoading,
    setVideoTimelineLoading,
}: IProps) {
    //-------------------------------------------------------------------------------------------------
    // ## HOOKS ##
    const { scenarioId, sceneId }: Readonly<Params<string>> = useParams();
    const canvasControlsRef = useRef<any>();
    const theme = useTheme();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    //-------------------------------------------------------------------------------------------------
    // ## USE SELECTOR ##

    //-------------------------------------------------------------------------------------------------
    // ## USE STATE ##

    // Image states
    const [currentImage, setCurrentImage] = useState<iImageProps | undefined>(undefined);

    // Video states

    const [video, setVideo] = useState<HTMLVideoElement>(document.createElement("video"));
    const [videoCurrentTime, setVideoCurrentTime] = useState<number>(0);
    const [videoLoadingState, setVideoLoadingState] = useState<boolean>(false);
    const [videoNextQuestion, setVideoNextQuestion] = useState<IHotspot | undefined>(undefined);
    const [proceedVideoOnComplete, setProceedVideoOnComplete] = useState<boolean>(false);
    const [changeSceneOnComplete, setChangeSceneOnComplete] = useState<string | undefined>(undefined);

    const {
        currentScene,
        currentlyDragging,
        currentHotspot,
        currentSidePanel,
        currentAudio,
        currentFeedbackImage,
        currentFeedbackVideo,
        currentFeedbackInput,
        currentSettingStartLocation,
        currentlyPreview,
        editHotspotDialogIsCurrentlyOpen,
        questionAudioAdd,
        currentSidePannelDesiredWidth,
    } = useAppSelector((state) => state.currentReducer);

    const [bottomDrawWidth, setBottomDrawWidth] = useState<number>(150);
    const [timelineMode, setTimelineMode] = useState<"Scenes" | "Timeline" | "Stepper">("Scenes");

    const [currentInterface, setCurrentInterface] = useState<IHotspot | undefined>(undefined);
    const [settingsDialog, setSettingsDialog] = useState<string | undefined>(undefined);

    const [forceVideoLoading, setForceVideoLoading] = useState<boolean>(false);

    const { data: hotspots } = useGetHotspotsQuery(sceneId ? sceneId : "");
    const { data: questionHotspots } = useGetGroupHotspotsQuery(videoNextQuestion ? videoNextQuestion.id : "");

    const { data: scenes } = useGetScenesQuery(scenarioId ? scenarioId : "");

    const [sortedQuestions, setSortedQuestions] = useState<IHotspot[]>([]);
    const [currentOpenHotspot, setCurrentOpenHotspot] = useState<string | undefined>(undefined);

    const [isHotspotCurrentlyBeingHoveredOver, setIHotspotCurrentlyBeingHoveredOver] = useState<boolean>(false);
    const [isCanvasInDrag, setIsCanvasInDrag] = useState<boolean>(false);

    const [richTextEditorContent, setRichTextEditorContent] = useState<string>("");
    const [richTextEditorChanged, setRichTextEditorChanged] = useState<boolean>(false);

    const [hotspotsClickedList, setHotspotsClickedList] = useState<string[]>([]);

    let richTextEditorDropMsgAndImage: undefined | { text: string; svgImage: any };

    if (currentlyDragging?.mediaType === EDraggableOptions.IMAGE) {
        richTextEditorDropMsgAndImage = {
            text: "Drop 2D Image",
            svgImage: DropImage,
        };
    } else if (currentlyDragging?.mediaType === EDraggableOptions.VIDEO) {
        richTextEditorDropMsgAndImage = {
            text: "Drop 2D Video",
            svgImage: DropVideo,
        };
    } else if (currentlyDragging?.mediaType === EDraggableOptions.AUDIO) {
        richTextEditorDropMsgAndImage = {
            text: "Drop 2D Audio",
            svgImage: DropAudio,
        };
    } else {
        richTextEditorDropMsgAndImage = undefined;
    }

    //-------------------------------------------------------------------------------------------------
    // ## USE EFFECTS ##
    document.addEventListener("visibilitychange", function () {
        if (
            document.hidden &&
            (currentScene.mediaType === EDraggableOptions.VIDEO ||
                currentScene.mediaType === EDraggableOptions.IMMERSIVE_VIDEO)
        ) {
            if (!video.paused) {
                setVideoCurrentState("Paused");
                video.pause();
            }
        }
    });

    useEffect(() => {
        if (timelineMode === "Timeline") {
            setTimelineMode("Scenes");
        }

        if (currentScene.startLocation) {
            handleLookAt(
                parseFloat(currentScene.startLocation[0]),
                parseFloat(currentScene.startLocation[1]),
                parseFloat(currentScene.startLocation[2]),
                false,
            );
        }
        setHotspotsClickedList([]);
    }, [sceneId]);

    //-------------------------------------------------------------------------------------------------
    // Test data image loading and video question loading
    useEffect(() => {
        if (currentScene.src) {
            if (
                (currentScene.mediaType === EDraggableOptions.IMMERSIVE_VIDEO ||
                    currentScene.mediaType === EDraggableOptions.VIDEO) &&
                hotspots
            ) {
                getNextQuestion(0);
            } else if (
                currentScene.mediaType === EDraggableOptions.IMMERSIVE_IMAGE ||
                currentScene.mediaType === EDraggableOptions.PASSTHROUGH
            ) {
                const loader = new TextureLoader();
                const backTexture = loader.load(currentScene.src + "/b.jpg");
                const frontTexture = loader.load(currentScene.src + "/f.jpg");
                const leftTexture = loader.load(currentScene.src + "/l.jpg");
                const rightTexture = loader.load(currentScene.src + "/r.jpg");
                const upTexture = loader.load(currentScene.src + "/u.jpg");
                const downTexture = loader.load(currentScene.src + "/d.jpg");

                setCurrentImage({
                    left: leftTexture,
                    right: rightTexture,
                    up: upTexture,
                    down: downTexture,
                    front: frontTexture,
                    back: backTexture,
                });
            }
            setVideoCurrentState(undefined);
            setCurrentInterface(undefined);
        }
    }, [currentScene.src]);

    useEffect(() => {
        // if (window.innerHeight / 4 > 250) {
        //     setBottomDrawWidth(250);
        // } else if (window.innerHeight / 4 < 130) {
        //     setBottomDrawWidth(130);
        // } else {
        //     setBottomDrawWidth(155);
        // }
    }, []);

    useEffect(() => {
        setHotspotsClickedList([]);
    }, [currentlyPreview]);

    useEffect(() => {
        if (questionAudioAdd && videoNextQuestion) {
            setVideoNextQuestion({
                ...videoNextQuestion,
                contents: {
                    ...videoNextQuestion.contents,
                    preClickContent: {
                        ...videoNextQuestion.contents.preClickContent,
                        question: {
                            ...videoNextQuestion.contents.preClickContent.question,
                            time: videoNextQuestion.contents.preClickContent.question!.time,
                            answers: [],
                            audio: questionAudioAdd,
                        },
                    },
                },
            });
            dispatch(setQuestionAudioAdd(undefined));
        }
    }, [questionAudioAdd]);

    useEffect(() => {
        setIHotspotCurrentlyBeingHoveredOver(false);

        if (currentScene.startLocation) {
            if (
                currentScene.startLocation[0] !== 0 &&
                currentScene.startLocation[1] !== 0 &&
                currentScene.startLocation[2] !== 0
            ) {
                handleLookAt(
                    parseFloat(currentScene.startLocation[0]),
                    parseFloat(currentScene.startLocation[1]),
                    parseFloat(currentScene.startLocation[2]),
                    currentSettingStartLocation,
                );
            } else {
                handleLookAt(0.1, 0.1, 10, currentSettingStartLocation);
            }
        } else {
            handleLookAt(0.1, 0.1, 10, currentSettingStartLocation);
        }

        dispatch(setCurrentStartingLocation(false));
    }, [currentScene.startLocation, currentScene.src]);

    //-------------------------------------------------------------------------------------------------
    // Pause the video on question
    useEffect(() => {
        if (currentScene.src) {
            if (
                videoNextQuestion?.contents?.preClickContent.question?.time &&
                videoCurrentTime >= videoNextQuestion?.contents?.preClickContent.question?.time
            ) {
                video.pause();
                if (videoNextQuestion.contents && videoNextQuestion.contents.preClickContent.question.audio) {
                    dispatch(setCurrentAudio(videoNextQuestion.contents.preClickContent.question.audio));
                }
                setVideoCurrentState("Question");
                dispatch(setCurrentQuestion(videoNextQuestion));
                setHotspotsClickedList([]);
                dispatch(
                    setCurrentHotspot({
                        currentHotspot: undefined,
                        open: false,
                    }),
                );
            }
        }
    }, [videoCurrentTime]);

    useEffect(() => {
        if (
            video &&
            videoCurrentTime &&
            currentScene &&
            currentScene.hotspots &&
            !currentAudio &&
            !currentFeedbackImage &&
            !currentFeedbackVideo &&
            !currentFeedbackInput &&
            proceedVideoOnComplete
        ) {
            getNextQuestion(videoCurrentTime + 0.05);
            setVideoCurrentState("Playing");
            video.play();
            setProceedVideoOnComplete(false);
        }
    }, [currentAudio, currentFeedbackImage, currentFeedbackVideo, currentFeedbackInput, proceedVideoOnComplete]);

    useEffect(() => {
        if (
            !currentAudio &&
            !currentFeedbackImage &&
            !currentFeedbackVideo &&
            !currentFeedbackInput &&
            changeSceneOnComplete !== undefined
        ) {
            setChangeSceneOnComplete(undefined);
            navigate(`/creator/${scenarioId}/${changeSceneOnComplete}`);
        }
    }, [currentAudio, currentFeedbackImage, currentFeedbackVideo, currentFeedbackInput, changeSceneOnComplete]);

    //-------------------------------------------------------------------------------------------------
    // ## CUSTOM FUNCTIONS ##
    function getNextQuestion(time: number) {
        if (hotspots) {
            let nextQuestion: IHotspot | undefined;

            hotspots.forEach((hotspotElement: IHotspot) => {
                if (hotspotElement.type === EDraggableOptions.QUESTION) {
                    if (hotspotElement.contents?.preClickContent.question?.time! > time) {
                        if (
                            nextQuestion === undefined ||
                            nextQuestion.contents?.preClickContent.question?.time! >
                                hotspotElement?.contents?.preClickContent.question?.time!
                        ) {
                            nextQuestion = hotspotElement;
                        }
                    }
                }
            });
            setVideoNextQuestion(nextQuestion);
        }
    }

    function handleLookAt(x: number, y: number, z: number, transition?: boolean) {
        console.log("DBG: im begin called", x, y, z);

        if (canvasControlsRef.current) {
            canvasControlsRef.current.lookAt(x, y, z, transition);
        }
    }

    function handleSetPosition(
        positionX: number,
        positionY: number,
        positionZ: number,
        lookAtX: number,
        lookAtY: number,
        lookAtZ: number,
        transition?: boolean,
    ) {
        if (canvasControlsRef.current) {
            canvasControlsRef.current.moveTo(positionX, positionY, positionZ, lookAtX, lookAtY, lookAtZ, transition);
        }
    }

    const [canvasIsBeingDragged, setCanvasIsBeingDragged] = useState<boolean>(false);

    useEffect(() => {
        console.log("testing: ", isHotspotCurrentlyBeingHoveredOver);
    }, [isHotspotCurrentlyBeingHoveredOver]);

    //-------------------------------------------------------------------------------------------------
    // ## RETURN ##
    return (
        <div
            // ref={creatorCanvasContainerRef}
            id="creator-canvas-container"
            style={{
                backgroundColor: theme.palette.background.paper,
                height: currentlyPreview
                    ? "calc(100vh - " + 69 + "px)"
                    : "calc(100vh - " + 69 + "px - " + bottomDrawWidth + "px)",
                width: currentlyPreview ? "calc(100vw)" : "calc(100% - " + (currentSidePanel.currentWidth + 80) + "px)",
                marginTop: "68px",
                userSelect: "none",
                position: "relative",
                // transition: "0.1s",
                overflow: "hidden",
            }}
        >
            {/*##############################################################################################*/}
            {/* Outside the canvas on image Hotspot */}
            {/*##############################################################################################*/}

            {/* #TODO: review */}
            {(hotspotLoading === "CreateHotspot" || hotspotLoading.includes("hotspotMove")) && (
                <>
                    <Box
                        component="div"
                        sx={{
                            position: "absolute",
                            height: "150px",
                            width: "150px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            zIndex: 100000,
                            top: "calc(50% - 80px)",
                            left: "calc(50% - 75px)",
                        }}
                    >
                        <CircularProgress
                            size={70}
                            thickness={4}
                            sx={{
                                position: "absolute",
                                zIndex: 100001,
                            }}
                        />
                        <Skeleton
                            variant="rectangular"
                            width={150}
                            height={150}
                            sx={{
                                borderRadius: "15px",
                                background: "rgba(30,30,30,0.7)",
                            }}
                        ></Skeleton>
                    </Box>
                </>
            )}

            {/*---------------------------------------------------------------------------------------*/}
            {/* VT Drop container to accept media from the left side panel */}
            {videoCurrentState !== "Question" && (
                <>
                    {currentScene.mediaType !== EDraggableOptions.DOCUMENT && !editHotspotDialogIsCurrentlyOpen && (
                        <VTSidePanelMediaDropContainer
                            mediaTypeBeingDragged={currentlyDragging?.mediaType}
                            location={DropPoint.DROP_CANVAS}
                            bottomDrawWidth={bottomDrawWidth}
                            droppableId={EDroppableContainerId.CANVAS_DROP}
                        />
                    )}

                    {currentScene.audio && <VTCreatorAudioControls audio={currentScene.audio} />}

                    {currentInterface ? (
                        <>
                            {currentlyPreview ? (
                                <VTViewerUserInterface
                                    currentInterface={currentInterface}
                                    setCurrentInterface={setCurrentInterface}
                                    handleProceedVideo={(delayInSeconds?: number) => {
                                        if (currentHotspot.currentHotspot) {
                                            dispatch(
                                                setCurrentHotspot({
                                                    currentHotspot: undefined,
                                                    open: false,
                                                }),
                                            );
                                        }
                                        setTimeout(
                                            () => {
                                                if (
                                                    currentAudio !== undefined ||
                                                    currentFeedbackImage !== undefined ||
                                                    currentFeedbackVideo !== undefined ||
                                                    currentFeedbackInput !== undefined
                                                ) {
                                                    setProceedVideoOnComplete(true);
                                                } else {
                                                    getNextQuestion(videoCurrentTime + 0.05);
                                                    setVideoCurrentState("Playing");
                                                    video.play();
                                                }
                                            },
                                            delayInSeconds ? delayInSeconds * 1000 : 0,
                                        );
                                        // return () => clearTimeout(timer);
                                    }}
                                    handleChangeScene={(sceneId: string, delayInSeconds?: number) => {
                                        setTimeout(
                                            () => {
                                                if (
                                                    currentAudio !== undefined ||
                                                    currentFeedbackImage !== undefined ||
                                                    currentFeedbackVideo !== undefined ||
                                                    currentFeedbackInput !== undefined
                                                ) {
                                                    setChangeSceneOnComplete(sceneId);
                                                } else {
                                                    navigate(`/creator/${scenarioId}/${sceneId}`);
                                                }
                                            },
                                            delayInSeconds ? delayInSeconds * 1000 : 0,
                                        );

                                        // return () => clearTimeout(timer);
                                    }}
                                />
                            ) : (
                                <VTUserInterface
                                    currentInterface={currentInterface}
                                    setCurrentInterface={setCurrentInterface}
                                    videoCurrentState={videoCurrentState}
                                />
                            )}
                        </>
                    ) : (
                        <Grid
                            sx={{
                                position: "absolute",
                                right: "50px",
                                width: 0,
                                height: "100%",
                            }}
                            container
                            alignContent={"center"}
                        >
                            {hotspots &&
                                hotspots.map(
                                    (hotspot: IHotspot) =>
                                        hotspot.type === EDraggableOptions.INTERFACE && (
                                            <Tooltip title={hotspot.contents.preClickContent.title}>
                                                <Grid item>
                                                    <VTButton
                                                        sx={{
                                                            zIndex: 10,
                                                            width: "10px",
                                                            height: "40px",
                                                            borderRadius: "15px 0px 0px 15px",
                                                            backgroundColor: "#a9a9a9",
                                                            ":hover": {
                                                                cursor: "pointer",
                                                                backgroundColor: "#888888",
                                                                boxShadow: "0 0 25px #2ad5c7",
                                                            },
                                                            marginBlock: "15px",
                                                        }}
                                                        onClick={() => setCurrentInterface(hotspot)}
                                                    >
                                                        <TextSnippetIcon />
                                                    </VTButton>
                                                </Grid>
                                            </Tooltip>
                                        ),
                                )}
                        </Grid>
                    )}
                </>
            )}

            {/*##############################################################################################*/}
            {/* Outside the canvas on Video  */}
            {/*##############################################################################################*/}
            {/*---------------------------------------------------------------------------------------*/}
            {/* On video add overlay video controls and question test */}
            {(currentScene.mediaType === EDraggableOptions.IMMERSIVE_VIDEO ||
                currentScene.mediaType === EDraggableOptions.VIDEO) && (
                <>
                    {/*-----------------------------------------------------------------------------------*/}
                    {/* video controls */}
                    <VTCreatorVideoControls
                        videoCurrentTime={videoCurrentTime}
                        setVideoCurrentTime={setVideoCurrentTime}
                        video={video}
                        videoNextQuestion={videoNextQuestion}
                        getNextQuestion={getNextQuestion}
                        setVideoNextQuestion={setVideoNextQuestion}
                        setTimelineMode={setTimelineMode}
                        videoCurrentState={videoCurrentState}
                        setVideoCurrentState={setVideoCurrentState}
                        currentInterfaceTrue={currentInterface !== undefined}
                        bottomDrawWidth={bottomDrawWidth}
                        hotspots={hotspots}
                        sortedQuestions={sortedQuestions}
                        setSortedQuestions={setSortedQuestions}
                        setHotspotLoading={setHotspotLoading}
                        createHotspot={createHotspot}
                        deleteHotspot={deleteHotspot}
                        setVideoTimelineLoading={setVideoTimelineLoading}
                    />

                    {videoLoadingState && (
                        <>
                            <CircularProgress sx={{ zIndex: 1000, position: "absolute", left: "48%", top: "48%" }} />
                        </>
                    )}

                    {/*---------------------------------------------------------------------------------*/}
                    {/* Question hotspot overlay */}
                    {videoNextQuestion && videoCurrentState === "Question" && (
                        <>
                            {/*---------------------------------------------------------------------------------*/}
                            {/* Question display */}
                            <VTCreatorQuestion
                                hotspot={videoNextQuestion}
                                marginTop={
                                    currentHotspot.currentHotspot && currentAttachedObject === undefined ? "90px" : "5%"
                                }
                            />

                            {/*---------------------------------------------------------------------------------*/}
                            {/* Question drop container */}
                            {/* {currentlyDragging && currentlyDragging.mediaType !== EDraggableOptions.SCENE && (
                                <VTCreatorDropContainer
                                    currentlyDragging={currentlyDragging}
                                    location={DropPoint.DROP_CANVAS}
                                    bottomDrawWidth={bottomDrawWidth}
                                    videoNextQuestionID={videoNextQuestion && videoNextQuestion.id}
                                    currentInterface={currentInterface}
                                />
                            )} */}

                            {!editHotspotDialogIsCurrentlyOpen && (
                                <VTSidePanelMediaDropContainer
                                    mediaTypeBeingDragged={currentlyDragging?.mediaType}
                                    location={DropPoint.DROP_CANVAS}
                                    bottomDrawWidth={bottomDrawWidth}
                                    droppableId={EDroppableContainerId.QUESTION_CANVAS_DROP}
                                />
                            )}

                            {/*---------------------------------------------------------------------------------*/}
                            {/* Question audio controls */}
                            {videoNextQuestion.contents &&
                                videoNextQuestion.contents.preClickContent.question?.audio && (
                                    <VTCreatorAudioControls
                                        audio={videoNextQuestion.contents.preClickContent.question?.audio}
                                        question={videoNextQuestion}
                                        setQuestion={setVideoNextQuestion}
                                    />
                                )}

                            {currentInterface ? (
                                <>
                                    {currentlyPreview ? (
                                        <VTViewerUserInterface
                                            currentInterface={currentInterface}
                                            setCurrentInterface={setCurrentInterface}
                                            handleProceedVideo={(delayInSeconds?: number) => {
                                                if (currentHotspot.currentHotspot) {
                                                    dispatch(
                                                        setCurrentHotspot({
                                                            currentHotspot: undefined,
                                                            open: false,
                                                        }),
                                                    );
                                                }
                                                setTimeout(
                                                    () => {
                                                        if (
                                                            currentAudio !== undefined ||
                                                            currentFeedbackImage !== undefined ||
                                                            currentFeedbackVideo !== undefined ||
                                                            currentFeedbackInput !== undefined
                                                        ) {
                                                            setProceedVideoOnComplete(true);
                                                        } else {
                                                            getNextQuestion(videoCurrentTime + 0.05);
                                                            setVideoCurrentState("Playing");
                                                            video.play();
                                                        }
                                                    },
                                                    delayInSeconds ? delayInSeconds * 1000 : 0,
                                                );
                                                // return () => clearTimeout(timer);
                                            }}
                                            handleChangeScene={(sceneId: string, delayInSeconds?: number) => {
                                                setTimeout(
                                                    () => {
                                                        if (
                                                            currentAudio !== undefined ||
                                                            currentFeedbackImage !== undefined ||
                                                            currentFeedbackVideo !== undefined ||
                                                            currentFeedbackInput !== undefined
                                                        ) {
                                                            setChangeSceneOnComplete(sceneId);
                                                        } else {
                                                            navigate(`/creator/${scenarioId}/${sceneId}`);
                                                        }
                                                    },
                                                    delayInSeconds ? delayInSeconds * 1000 : 0,
                                                );

                                                // return () => clearTimeout(timer);
                                            }}
                                        />
                                    ) : (
                                        <VTUserInterface
                                            currentInterface={currentInterface}
                                            setCurrentInterface={setCurrentInterface}
                                            videoCurrentState={videoCurrentState}
                                        />
                                    )}
                                </>
                            ) : (
                                <Grid
                                    sx={{
                                        position: "absolute",
                                        right: "50px",
                                        width: 0,
                                        height: "100%",
                                    }}
                                    container
                                    alignContent={"center"}
                                >
                                    {videoCurrentState === "Question" &&
                                        questionHotspots &&
                                        questionHotspots.map(
                                            (hotspot: IHotspot) =>
                                                hotspot.type === EDraggableOptions.INTERFACE && (
                                                    <Tooltip title={hotspot.contents.preClickContent.title}>
                                                        <Grid item>
                                                            <VTButton
                                                                sx={{
                                                                    zIndex: 10,
                                                                    width: "10px",
                                                                    height: "40px",
                                                                    borderRadius: "15px 0px 0px 15px",
                                                                    backgroundColor: "#a9a9a9",
                                                                    ":hover": {
                                                                        cursor: "pointer",
                                                                        backgroundColor: "#888888",
                                                                        boxShadow: "0 0 25px #2ad5c7",
                                                                    },
                                                                    marginBlock: "15px",
                                                                }}
                                                                onClick={() => setCurrentInterface(hotspot)}
                                                            >
                                                                <TextSnippetIcon />
                                                            </VTButton>
                                                        </Grid>
                                                    </Tooltip>
                                                ),
                                        )}
                                </Grid>
                            )}
                        </>
                    )}
                </>
            )}

            {/*-------------------------------------------------------------------------------------*/}
            {/* Show default grid for 3D scene*/}
            {(currentScene.mediaType === EDraggableOptions.MODEL_3D ||
                currentScene.id === "8cf5bff5-fd50-4344-9008-2bb037f96893") && <ThreeDViewer />}

            {currentScene.mediaType !== EDraggableOptions.DOCUMENT &&
                currentScene.id !== "8cf5bff5-fd50-4344-9008-2bb037f96893" && (
                    <>
                        {/*---------------------------------------------------------------------------------------*/}
                        {/* VT canvas */}
                        <VTCanvas
                            disableCameraControls={isHotspotCurrentlyBeingHoveredOver && !isCanvasInDrag}
                            debug={false}
                            style={{
                                width: "calc(100% - " + (currentInterface ? 400 : 0) + "px)",
                                height: "100%",
                                position: "absolute",
                                top: 0,
                            }}
                            attachToCameraRef={currentAttachedObject}
                            ref={canvasControlsRef}
                            limitZoom={currentScene.mediaType !== EDraggableOptions.MODEL_3D}
                            // onDragging={{
                            //     allowEventToBeCalled: false,
                            //     callback() {
                            //         console.log("dragging");
                            //     },
                            // }}
                            userMouseDown={() => {
                                setIsCanvasInDrag(true);
                            }}
                            userMouseUp={() => {
                                setIsCanvasInDrag(false);
                            }}
                        >
                            <>
                                {/*-------------------------------------------------------------------------------------*/}
                                {/* App image cube */}
                                {(currentScene.mediaType === EDraggableOptions.IMMERSIVE_IMAGE ||
                                    (currentScene.mediaType === EDraggableOptions.PASSTHROUGH && currentScene.src)) && (
                                    <VTCube sideLength={1000} loading={!currentScene.src} image={currentImage} />
                                )}

                                {/*-------------------------------------------------------------------------------------*/}
                                {/* Show default sphere for AR scene*/}
                                {currentScene.mediaType === EDraggableOptions.PASSTHROUGH && <Passthrough />}

                                {/*-------------------------------------------------------------------------------------*/}
                                {/* Show default scene in none has been added */}
                                {!currentScene.id && <VTCube sideLength={300} image={undefined} />}

                                {/*-------------------------------------------------------------------------------------*/}
                                {/* App video sphere */}
                                {(currentScene.mediaType === EDraggableOptions.IMMERSIVE_VIDEO ||
                                    currentScene.mediaType === EDraggableOptions.VIDEO) && (
                                    <>
                                        {currentScene.mediaType === EDraggableOptions.IMMERSIVE_VIDEO && (
                                            <VTSphere
                                                video={video}
                                                setVideo={setVideo}
                                                videoSrc={forceVideoLoading ? "" : currentScene.src}
                                                setVideoCurrentTime={setVideoCurrentTime}
                                                setVideoLoadingState={setVideoLoadingState}
                                                handleVideoEnded={() => {
                                                    if (currentScene.context.moveToScene) {
                                                        setTimeout(
                                                            () => {
                                                                navigate(
                                                                    `/creator/${scenarioId}/${currentScene.context.moveToScene}`,
                                                                );
                                                            },
                                                            currentScene.context.moveToSceneDelay
                                                                ? currentScene.context.moveToSceneDelay * 1000
                                                                : 0,
                                                        );
                                                    }
                                                }}
                                                autoLoop={
                                                    // @ts-ignore
                                                    currentScene.context.loopable
                                                        ? // @ts-ignore
                                                          currentScene.context.loopable
                                                        : false
                                                }
                                            />
                                        )}

                                        {currentScene.mediaType === EDraggableOptions.VIDEO && (
                                            <VTVideo
                                                video={video}
                                                setVideo={setVideo}
                                                videoSrc={currentScene.src}
                                                setVideoCurrentTime={setVideoCurrentTime}
                                            />
                                        )}

                                        {/*---------------------------------------------------------------------------------*/}
                                        {/* video paused */}
                                        {videoCurrentState === "Question" &&
                                            questionHotspots &&
                                            questionHotspots.map((hotspot: IHotspot) => (
                                                <>
                                                    {hotspot.type !== EDraggableOptions.INTERFACE &&
                                                        hotspot.type !== EDraggableOptions.QUESTION &&
                                                        !hotspotLoading.includes(hotspot.id) && (
                                                            <>
                                                                <VTHotspotHTMLWrapper
                                                                    onHotspotCurrentlyBeingHoveredOver={(
                                                                        isBeingHoveredOver: boolean,
                                                                    ) => {
                                                                        setIHotspotCurrentlyBeingHoveredOver(
                                                                            isBeingHoveredOver,
                                                                        );
                                                                    }}
                                                                    key={hotspot.id}
                                                                    hotspot={hotspot}
                                                                    videoCurrentState={videoCurrentState}
                                                                    hotspotLoading={hotspotLoading}
                                                                    setHotspotLoading={setHotspotLoading}
                                                                    deleteHotspot={deleteHotspot}
                                                                    updateHotspot={updateHotspot}
                                                                    currentOpenHotspot={currentOpenHotspot}
                                                                    setCurrentOpenHotspot={setCurrentOpenHotspot}
                                                                    handleProceedVideo={(delayInSeconds?: number) => {
                                                                        setIHotspotCurrentlyBeingHoveredOver(false);

                                                                        if (currentHotspot.currentHotspot) {
                                                                            dispatch(
                                                                                setCurrentHotspot({
                                                                                    currentHotspot: undefined,
                                                                                    open: false,
                                                                                }),
                                                                            );
                                                                        }
                                                                        setTimeout(
                                                                            () => {
                                                                                if (
                                                                                    currentAudio !== undefined ||
                                                                                    currentFeedbackImage !==
                                                                                        undefined ||
                                                                                    currentFeedbackVideo !==
                                                                                        undefined ||
                                                                                    currentFeedbackInput !== undefined
                                                                                ) {
                                                                                    setProceedVideoOnComplete(true);
                                                                                } else {
                                                                                    getNextQuestion(
                                                                                        videoCurrentTime + 0.05,
                                                                                    );
                                                                                    setVideoCurrentState("Playing");
                                                                                    video.play();
                                                                                }
                                                                            },
                                                                            delayInSeconds ? delayInSeconds * 1000 : 0,
                                                                        );

                                                                        // return () => clearTimeout(timer);
                                                                    }}
                                                                    handleChangeScene={(
                                                                        sceneId: string,
                                                                        delayInSeconds?: number,
                                                                    ) => {
                                                                        setTimeout(
                                                                            () => {
                                                                                if (
                                                                                    currentAudio !== undefined ||
                                                                                    currentFeedbackImage !==
                                                                                        undefined ||
                                                                                    currentFeedbackVideo !==
                                                                                        undefined ||
                                                                                    currentFeedbackInput !== undefined
                                                                                ) {
                                                                                    setChangeSceneOnComplete(sceneId);
                                                                                } else {
                                                                                    navigate(
                                                                                        `/creator/${scenarioId}/${sceneId}`,
                                                                                    );
                                                                                }
                                                                            },
                                                                            delayInSeconds ? delayInSeconds * 1000 : 0,
                                                                        );
                                                                    }}
                                                                    setCurrentAttachedObject={setCurrentAttachedObject}
                                                                    currentAttachedObject={currentAttachedObject}
                                                                    hotspotsClickedList={hotspotsClickedList}
                                                                    setHotspotsClickedList={setHotspotsClickedList}
                                                                    possibleHotspotList={[
                                                                        ...questionHotspots
                                                                            .filter(
                                                                                (filterElement: IHotspot) =>
                                                                                    filterElement.type !==
                                                                                        EDraggableOptions.INTERFACE &&
                                                                                    filterElement.actions?.movement
                                                                                        ?.sceneId === undefined &&
                                                                                    filterElement.actions?.appFeedback
                                                                                        ?.proceed === undefined,
                                                                            )
                                                                            .map(
                                                                                (mappingElement: IHotspot) =>
                                                                                    mappingElement.id,
                                                                            ),
                                                                    ]}
                                                                />
                                                            </>
                                                        )}
                                                </>
                                            ))}
                                    </>
                                )}

                                {/*  <VTVRLoadingSpinner /> */}

                                {/*-------------------------------------------------------------------------------------*/}
                                {/* Render hotspots */}
                                {hotspots &&
                                    (videoCurrentState !== "Question" ||
                                        (currentScene.mediaType !== EDraggableOptions.IMMERSIVE_VIDEO &&
                                            currentScene.mediaType !== EDraggableOptions.VIDEO)) &&
                                    hotspots.map((hotspot: IHotspot, i: number) => (
                                        <>
                                            {hotspot.type !== EDraggableOptions.QUESTION &&
                                                hotspot.type !== EDraggableOptions.INTERFACE &&
                                                hotspotLoading !== `hotspotMove${hotspot.id}` && (
                                                    <>
                                                        {hotspot.type === EDraggableOptions.IMMERSIVE_MODEL ? (
                                                            <ImmersiveModel
                                                                hotspot={hotspot}
                                                                setCurrentHotspot={setCurrentHotspot}
                                                            />
                                                        ) : (
                                                            <VTHotspotHTMLWrapper
                                                                onHotspotCurrentlyBeingHoveredOver={(
                                                                    isBeingHoveredOver: boolean,
                                                                ) => {
                                                                    setIHotspotCurrentlyBeingHoveredOver(
                                                                        isBeingHoveredOver,
                                                                    );
                                                                }}
                                                                key={hotspot.id}
                                                                hotspot={hotspot}
                                                                currentAttachedObject={currentAttachedObject}
                                                                setCurrentAttachedObject={setCurrentAttachedObject}
                                                                videoCurrentState={videoCurrentState}
                                                                hotspotLoading={hotspotLoading}
                                                                setHotspotLoading={setHotspotLoading}
                                                                deleteHotspot={deleteHotspot}
                                                                updateHotspot={updateHotspot}
                                                                currentOpenHotspot={currentOpenHotspot}
                                                                setCurrentOpenHotspot={setCurrentOpenHotspot}
                                                                handleChangeScene={(
                                                                    sceneId: string,
                                                                    delayInSeconds?: number,
                                                                ) => {
                                                                    setTimeout(
                                                                        () => {
                                                                            if (
                                                                                currentAudio !== undefined ||
                                                                                currentFeedbackImage !== undefined ||
                                                                                currentFeedbackVideo !== undefined ||
                                                                                currentFeedbackInput !== undefined
                                                                            ) {
                                                                                setChangeSceneOnComplete(sceneId);
                                                                            } else {
                                                                                navigate(
                                                                                    `/creator/${scenarioId}/${sceneId}`,
                                                                                );
                                                                            }
                                                                        },
                                                                        delayInSeconds ? delayInSeconds * 1000 : 0,
                                                                    );

                                                                    // return () => clearTimeout(timer);
                                                                }}
                                                                hotspotsClickedList={hotspotsClickedList}
                                                                setHotspotsClickedList={setHotspotsClickedList}
                                                                possibleHotspotList={[
                                                                    ...hotspots
                                                                        .filter(
                                                                            (filterElement: IHotspot) =>
                                                                                filterElement.type !==
                                                                                    EDraggableOptions.QUESTION &&
                                                                                filterElement.type !==
                                                                                    EDraggableOptions.INTERFACE &&
                                                                                filterElement.actions?.movement
                                                                                    ?.sceneId === undefined,
                                                                        )
                                                                        .map(
                                                                            (mappingElement: IHotspot) =>
                                                                                mappingElement.id,
                                                                        ),
                                                                ]}
                                                            />
                                                        )}
                                                    </>
                                                )}
                                        </>
                                    ))}
                            </>
                        </VTCanvas>
                    </>
                )}

            {currentScene.mediaType === EDraggableOptions.DOCUMENT && (
                <>
                    {currentlyPreview ? (
                        <Container>
                            <VTRichTextEditorContentPreview
                                sx={{
                                    width: "100%",
                                    height: "calc(100vh - 68px)",
                                    backgroundColor: "#333333",
                                    overflowY: "auto",
                                    padding: "50px 50px",
                                }}
                                htmlContent={currentScene.src}
                            />
                        </Container>
                    ) : (
                        // TODO: add container back in
                        // <Container>
                        <VTRichTextEditor
                            save={{
                                onSave: () => {
                                    updateScene({ id: sceneId, src: richTextEditorContent });
                                    setRichTextEditorChanged(false);
                                },
                                disable: !richTextEditorChanged,
                            }}
                            // @ts-ignore
                            onRichTextEditorIdRefReady={(id: string) => {
                                dispatch(setCurrentRichTextEditorNormalModeId(id));
                            }}
                            // currentlyDragging={currentlyDraggingObj}
                            dropTextAndSvgImage={richTextEditorDropMsgAndImage}
                            onClickImageIcon={() => {
                                dispatch(
                                    setCurrentSidePanel({
                                        currentTab: "Images",
                                        currentWidth: currentSidePannelDesiredWidth,
                                        option: undefined,
                                    }),
                                );
                            }}
                            onClickVideoIcon={() => {
                                dispatch(
                                    setCurrentSidePanel({
                                        currentTab: "Videos",
                                        currentWidth: currentSidePannelDesiredWidth,
                                        option: undefined,
                                    }),
                                );
                            }}
                            onClickAudioIcon={() => {
                                dispatch(
                                    setCurrentSidePanel({
                                        currentTab: "Audio",
                                        currentWidth: currentSidePannelDesiredWidth,
                                        option: undefined,
                                    }),
                                );
                            }}
                            startingHtmlContent={currentScene.src}
                            onChange={(htmlStringContent: string) => {
                                if (!richTextEditorChanged) {
                                    setRichTextEditorChanged(true);
                                }
                                // if (currentPrePostTab === "post") {
                                setRichTextEditorContent(htmlStringContent);
                                // } else {
                                //   setPreClickContent(htmlStringContent);
                                // }
                            }}
                            sx={{
                                height: "100%",
                                borderRadius: 0,
                                "& .MuiBox-root": {
                                    "& .css-1sj301d": {
                                        paddingLeft: "40px",
                                    },
                                },
                            }}
                        />
                        // </Container>
                    )}
                </>
            )}

            <VTCreatorTimelineContainer
                bottomDrawWidth={bottomDrawWidth}
                setBottomDrawWidth={setBottomDrawWidth}
                timelineMode={timelineMode}
                setTimelineMode={setTimelineMode}
                mediaType={currentScene.mediaType}
                videoNextQuestion={videoNextQuestion}
                setVideoCurrentState={setVideoCurrentState}
                video={video}
                getNextQuestion={getNextQuestion}
                hotspots={hotspots}
                videoCurrentTime={videoCurrentTime}
                sortedQuestions={sortedQuestions}
                setSortedQuestions={setSortedQuestions}
                sceneLoading={sceneLoading}
                setSceneLoading={setSceneLoading}
                setSettingsDialog={setSettingsDialog}
                createScene={createScene}
                updateScene={updateScene}
                createSceneLoading={createSceneLoading}
                setChangeSceneBuffer={setChangeSceneBuffer}
                videoTimelineLoading={videoTimelineLoading}
                setVideoTimelineLoading={setVideoTimelineLoading}
                createHotspot={createHotspot}
                setVideoNextQuestion={setVideoNextQuestion}
                videoCurrentState={videoCurrentState}
            />

            {currentHotspot.currentHotspot && (
                <VTHotspotQuickEdit
                    videoCurrentState={videoCurrentState}
                    currentInterface={currentInterface}
                    setHotspotLoading={setHotspotLoading}
                    updateHotspot={updateHotspot}
                    currentAttachedObject={currentAttachedObject}
                />
            )}

            <VTHotspotEditDialog
                sx={{
                    "& .MuiDialogContent-root": {
                        height: "1000px", // make dialog a fixed height
                    },
                    position: "absolute",
                    left: currentSidePanel.currentWidth + 80,
                    height: "90vh",
                    marginTop: "80px",
                }}
                videoCurrentState={videoCurrentState}
                videoNextQuestion={videoNextQuestion}
            />

            {settingsDialog !== undefined && scenes && (
                <VTSceneSettings
                    scenes={scenes}
                    scene={scenes.find((filterElement: IScene) => filterElement.id === settingsDialog)!}
                    dialogOpen={settingsDialog}
                    setDialogOpen={setSettingsDialog}
                    handelSettingSave={(e: IThreeSixtyVideoContext) => {
                        updateScene({ id: sceneId, context: e });
                        setForceVideoLoading(true);
                        const timeoutId = setTimeout(() => {
                            setForceVideoLoading(false);
                        }, 500);
                        return () => clearTimeout(timeoutId);
                    }}
                    sx={{
                        left: currentSidePanel.currentWidth + 80,
                    }}
                />
            )}
        </div>
    );
}
