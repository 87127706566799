//---------------------------------------------------------------------------------------------------
// ## IMPORTS ##
// Library Imports
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { IAPIResponse } from "@virtus-tech-repository/virtus-tech-repository/lib/models/api.model";
import { IScenario, IScene } from "@virtus-tech-repository/virtus-tech-repository/lib/models/scenario.model";

const isDev: boolean = process.env.REACT_APP_IS_LOCAL === "true";

// Custom components imports (and hooks and helpers)
export const ScenarioApi = createApi({
    reducerPath: "scenario",
    tagTypes: ["scenario"],
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_NINJA_ENDPOINT,
    }),
    endpoints: (builder) => ({
        getScenarios: builder.query<IScenario[], string>({
            query: (referenceId) => ({
                url: `scenarios/${referenceId}`,
                method: "GET",
            }),
            providesTags: (result) =>
                result
                    ? [
                          ...result.map(({ id }: IScenario) => ({
                              type: "scenario" as const,
                              id,
                          })),
                          { type: "scenario", id: "LIST" },
                      ]
                    : [{ type: "scenario", id: "LIST" }],
        }),
        createScenario: builder.mutation<IAPIResponse, IScenario>({
            query: (body) => ({
                url: `scenario`,
                method: "POST",
                body: body,
            }),
            invalidatesTags: [{ type: "scenario", id: "LIST" }],
        }),
        updateScenario: builder.mutation<IAPIResponse, Partial<IScenario>>({
            query: (body) => ({
                url: `scenario/${body.id}`,
                method: "PUT",
                body,
            }),
            invalidatesTags: [{ type: "scenario", id: "LIST" }],
        }),
        publishScenario: builder.mutation<IAPIResponse, { id: string; published: boolean }>({
            query: (body) => ({
                url: `publish/${body.id}`,
                method: "POST",
                body,
            }),
            invalidatesTags: [{ type: "scenario", id: "LIST" }],
        }),

        duplicateScenario: builder.mutation<IAPIResponse, { userId: string; scenarioId: string }>({
            query: ({ userId, scenarioId }) => ({
                url: `duplicate/${userId}/${scenarioId} `,
                method: "POST",
            }),
            invalidatesTags: [{ type: "scenario", id: "LIST" }],
        }),

        duplicateDemoScenario: builder.mutation<IAPIResponse, { userId: string; scenarioId: string }>({
            query: ({ userId, scenarioId }) => ({
                url: `duplicateDemo/${userId}/${scenarioId} `,
                method: "POST",
            }),
            invalidatesTags: [{ type: "scenario", id: "LIST" }],
        }),

        deleteScenario: builder.mutation<IAPIResponse, string>({
            query: (scenarioId) => ({
                url: `scenario/${scenarioId}`,
                method: "DELETE",
            }),
            invalidatesTags: [{ type: "scenario", id: "LIST" }],
        }),
    }),
});

export const {
    useGetScenariosQuery,
    useCreateScenarioMutation,
    useUpdateScenarioMutation,
    useDeleteScenarioMutation,
    useDuplicateScenarioMutation,
    useDuplicateDemoScenarioMutation,
    usePublishScenarioMutation,
} = ScenarioApi;
