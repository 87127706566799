//---------------------------------------------------------------------------------------------------
// ## IMPORTS ##
// Library Imports
// Material ui Imports
// Custom components imports (and hooks and helpers)
// Media imports

import { Grid, LinearProgress, Slider, SxProps, TextField, Tooltip } from "@mui/material";
import { VTButton, VTDialog, VTTypography } from "@virtus-tech-repository/virtus-tech-repository";

import PauseRounded from "@mui/icons-material/PauseRounded";
import PlayArrowRounded from "@mui/icons-material/PlayArrowRounded";
import FastRewindRounded from "@mui/icons-material/FastRewindRounded";
import FastForwardRounded from "@mui/icons-material/FastForwardRounded";
import {
    AddRounded,
    CancelRounded,
    SaveRounded,
    DeleteRounded,
    VolumeOffRounded,
    VolumeUpRounded,
    SettingsRounded,
} from "@mui/icons-material";
import { useEffect, useState } from "react";
import {
    setCurrentAudio,
    setCurrentHotspot,
    setCurrentQuestion,
    setCurrentSidePanel,
} from "../../store/slices/current.slice";
import { useDispatch } from "react-redux";
import { getTimeElapsedInStopWatchFormatString } from "../../utils/getTimeElapsedInStopWatchFormattedString";
import { IScene } from "@virtus-tech-repository/virtus-tech-repository/lib/models/scenario.model";
import { IHotspot } from "@virtus-tech-repository/virtus-tech-repository/lib/models/hotspot.model";
import { EDraggableOptions } from "@virtus-tech-repository/virtus-tech-repository/lib/models/media.model";
import { Params, useParams } from "react-router-dom";
import { blankQuestionHotspot } from "../../helpers/hotspotDataGenerator";
import { useAppSelector } from "../../store/hooks";
import { useUpdateHotspotMutation } from "../../services/hotspot.service";
import { useUpdateSceneMutation } from "../../services/scene.service";

//---------------------------------------------------------------------------------------------------
// ## MUI STYLED ##
// Layout stuff remains inline, other styling pulled into it's own components

//---------------------------------------------------------------------------------------------------
// ## INTERFACES ##
// Optional variables towards the bottom
interface IProps {
    videoCurrentTime: number;
    setVideoCurrentTime: (videoCurrentTime: number) => void;
    videoCurrentState: string | undefined;
    setVideoCurrentState: any;
    video: HTMLVideoElement;
    videoNextQuestion: IHotspot | undefined;
    getNextQuestion: (time: number) => void;
    setVideoNextQuestion: (videoNextQuestion: IHotspot | undefined) => void;

    setTimelineMode: (timelineMode: "Scenes" | "Timeline" | "Stepper") => void;
    currentInterfaceTrue: boolean;
    sx?: SxProps;
    bottomDrawWidth: number;
    hotspots: IHotspot[] | undefined;
    setVideoSettingDialog?: (videoSettingDialog: boolean) => void;
    sortedQuestions: IHotspot[];
    setSortedQuestions: (sortedQuestions: IHotspot[]) => void;
    createHotspot: any;
    deleteHotspot: any;
    setHotspotLoading: (hotspotLoading: string) => void;
    setVideoTimelineLoading: (videoTimelineLoading: string) => void;
}

//---------------------------------------------------------------------------------------------------
// ## COMPONENTS ##
// Only display layouts in pages
export default function VTCreatorVideoControls({
    videoCurrentTime,
    setVideoCurrentTime,
    videoCurrentState,
    setVideoCurrentState,
    video,
    videoNextQuestion,
    getNextQuestion,
    setVideoNextQuestion,
    setTimelineMode,
    sx,
    currentInterfaceTrue,
    bottomDrawWidth,
    hotspots = [],
    sortedQuestions,
    setSortedQuestions,
    createHotspot,
    deleteHotspot,
    setVideoTimelineLoading,
}: IProps) {
    //-------------------------------------------------------------------------------------------------
    // ## HOOKS ##
    const { sceneId }: Readonly<Params<string>> = useParams();
    const dispatch = useDispatch();
    const [updateHotspot] = useUpdateHotspotMutation();

    const [updateScene] = useUpdateSceneMutation();

    const { currentSidePanel, currentlyPreview, currentScene } = useAppSelector((state) => state.currentReducer);
    //-------------------------------------------------------------------------------------------------
    // ## USE SELECTOR ##

    //-------------------------------------------------------------------------------------------------
    // ## USE STATE ##
    const [currentTimeEditing, setCurrentTimeEditing] = useState<boolean>(false);
    const [currentTimeEditInput, setCurrentTimeEditInput] = useState<number>(videoCurrentTime);
    const [currentlyMuted, setCurrentMuted] = useState<boolean>(false);
    const [previousQuestionTime, setPreviousQuestionTime] = useState<number>(0);
    const { currentAudio } = useAppSelector((state) => state.currentReducer);

    const [editStopPointTimeOpen, setEditStopPointTimeOpen] = useState<boolean>(false);
    const [editStopPointTimeDisabled, setEditStopPointTimeDisabled] = useState<boolean>(false);
    const [editStopPointTime, setEditStopPointTime] = useState<number>(0);

    //-------------------------------------------------------------------------------------------------
    // ## USE EFFECTS ##
    useEffect(() => {
        if (video.muted !== currentlyMuted) {
            setCurrentMuted(video.muted);
        }
    }, [video.muted]);

    useEffect(() => {
        if (editStopPointTime !== 0) {
            setEditStopPointTimeDisabled(
                sortedQuestions.filter(
                    (findTimeElement: IHotspot) =>
                        videoNextQuestion !== findTimeElement &&
                        findTimeElement.contents.preClickContent.question!.time - 0.3 <= editStopPointTime &&
                        findTimeElement.contents.preClickContent.question!.time + 0.3 >= editStopPointTime,
                ).length > 0,
            );
        }
    }, [editStopPointTime]);

    useEffect(() => {
        if (videoNextQuestion) {
            let tempPreviousQuestionTime = 0;
            hotspots
                .filter((hotspotFilterElement: IHotspot) => hotspotFilterElement.type === EDraggableOptions.QUESTION)
                .forEach((hotspotElement: IHotspot) => {
                    if (
                        hotspotElement.contents.preClickContent.question!.time > tempPreviousQuestionTime &&
                        hotspotElement.contents.preClickContent.question!.time <
                            videoNextQuestion.contents.preClickContent.question!.time
                    ) {
                        tempPreviousQuestionTime = hotspotElement.contents.preClickContent.question!.time;
                    }
                });

            setPreviousQuestionTime(tempPreviousQuestionTime);
        } else {
            let tempPreviousQuestionTime = 0;
            hotspots
                .filter((hotspotFilterElement: IHotspot) => hotspotFilterElement.type === EDraggableOptions.QUESTION)
                .forEach((hotspotElement: IHotspot) => {
                    if (hotspotElement.contents.preClickContent.question!.time > tempPreviousQuestionTime) {
                        tempPreviousQuestionTime = hotspotElement.contents.preClickContent.question!.time;
                    }
                });

            setPreviousQuestionTime(tempPreviousQuestionTime);
        }
    }, [videoNextQuestion]);

    let BufferedZones = () => {
        return (
            <>
                {Array.from(Array(video.buffered.length), (e, i) => {
                    return (
                        <div
                            style={{
                                position: "absolute",
                                left: `${(video.buffered.start(i) / video.duration) * 100}%`,
                                right: `${100 - (video.buffered.end(i) / video.duration) * 100}%`,
                                backgroundColor: "#ae45ff",
                                height: "4px",
                                top: "-2px",
                            }}
                        />
                    );
                })}
            </>
        );
    };

    let SliderZone = () => {
        return (
            <Slider
                sx={{
                    width: "calc(100% - " + 100 + "px)",
                    zIndex: 1000,
                }}
                value={videoCurrentTime}
                max={video.duration}
                onChange={(_, value) => {
                    video.currentTime = value as number;
                    getNextQuestion(value as number);
                    if (videoCurrentState === "Question") {
                        setVideoCurrentState("Paused");
                    }
                    dispatch(
                        setCurrentHotspot({
                            currentHotspot: undefined,
                            open: false,
                        }),
                    );
                }}
                step={0.001}
            />
        );
    };

    //-------------------------------------------------------------------------------------------------
    // ## CUSTOM FUNCTIONS ##

    //-------------------------------------------------------------------------------------------------
    // ## RETURN ##
    // If page is large render all buttons


    return (
        <Grid
            container
            sx={{
                ...sx,
                position: "absolute",
                bottom: bottomDrawWidth === 0 ? "35px" : "12px",
                width: currentInterfaceTrue ? "calc(100% - 400px)" : "100%",
                zIndex: 1000,
            }}
        >
            <Grid
                item
                xs={12}
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginBottom: "-30px",
                    borderRadius: "15px",
                }}
            >
                {video.buffered.length >= 1 && (
                    <Grid
                        sx={{
                            width: "calc(100% - " + 100 + "px)",
                            zIndex: 800,
                            position: "relative",
                        }}
                    >
                        <BufferedZones />
                    </Grid>
                )}
            </Grid>
            <Grid
                item
                xs={12}
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <SliderZone />
            </Grid>

            {/*-------------------------------------------------------------------------------*/}
            {/* Rewind button */}
            <Grid item container xs={12} alignItems={"center"} justifyContent={"center"}>
                <VTButton
                    sx={{ marginRight: "5px" }}
                    type={"icon"}
                    onClick={() => {
                        video.currentTime = videoCurrentTime - 5;
                        getNextQuestion(videoCurrentTime - 5);
                        if (videoCurrentState === "Question") {
                            setVideoCurrentState("Paused");
                        }
                    }}
                    disabled={videoCurrentTime <= 5 && true}
                >
                    <FastRewindRounded />
                </VTButton>

                {/*-----------------------------------------------------------------------------*/}
                {/* Play / Pause controls */}
                {videoCurrentState === "Playing" ? (
                    <VTButton
                        sx={{ marginRight: "5px" }}
                        type={"icon"}
                        onClick={() => {
                            video.pause();
                            setVideoCurrentState("Paused");
                            dispatch(
                                setCurrentHotspot({
                                    currentHotspot: undefined,
                                    open: false,
                                }),
                            );
                        }}
                    >
                        <PauseRounded />
                    </VTButton>
                ) : (
                    <VTButton
                        sx={{ marginRight: "5px" }}
                        type={"icon"}
                        onClick={() => {
                            if (videoCurrentState === "Question") {
                                getNextQuestion(videoCurrentTime + 0.01);
                            } else {
                                getNextQuestion(videoCurrentTime);
                            }
                            if (currentAudio !== "") {
                                dispatch(setCurrentAudio(""));
                            }
                            setVideoCurrentState("Playing");
                            video.play();
                            dispatch(
                                setCurrentHotspot({
                                    currentHotspot: undefined,
                                    open: false,
                                }),
                            );
                        }}
                    >
                        <PlayArrowRounded />
                    </VTButton>
                )}

                {/*-----------------------------------------------------------------------------*/}
                {/* Fast forward controls */}
                <VTButton
                    sx={{ marginRight: "27px" }}
                    type={"icon"}
                    onClick={() => {
                        video.currentTime = videoCurrentTime + 5;
                        getNextQuestion(videoCurrentTime + 5);
                        if (videoCurrentState === "Question") {
                            setVideoCurrentState("Paused");
                        }
                        dispatch(
                            setCurrentHotspot({
                                currentHotspot: undefined,
                                open: false,
                            }),
                        );
                    }}
                    disabled={video.duration - videoCurrentTime <= 5 && true}
                >
                    <FastForwardRounded />
                </VTButton>

                {/*-----------------------------------------------------------------------------*/}
                {/* Current time / duration */}

                <Grid
                    sx={{
                        width: "135px",
                        backgroundColor: "rgba(0,0,0,0.2)",
                        borderRadius: "15px",
                        padding: "2px",
                    }}
                    container
                    justifyContent={"center"}
                    alignContent={"center"}
                >
                    {currentTimeEditing ? (
                        <>
                            <form
                                onSubmit={() => {
                                    video.pause();
                                    video.currentTime = currentTimeEditInput;
                                    getNextQuestion(currentTimeEditInput);
                                    setVideoCurrentState("Paused");
                                    setCurrentTimeEditing(false);
                                    dispatch(
                                        setCurrentHotspot({
                                            currentHotspot: undefined,
                                            open: false,
                                        }),
                                    );
                                }}
                            >
                                <TextField
                                    sx={{
                                        marginLeft: "10px",
                                        marginTop: "5px",
                                        height: "33px",
                                        width: "130px",
                                        borderRadius: "15px",
                                    }}
                                    autoFocus
                                    type={"number"}
                                    inputProps={{
                                        style: {
                                            // Set the content width; effects functionality not styling
                                            height: "1px",
                                            border: "none",
                                        },
                                    }}
                                    value={currentTimeEditInput}
                                    onChange={(e: any) => {
                                        setCurrentTimeEditInput(e.target.value);
                                        dispatch(
                                            setCurrentHotspot({
                                                currentHotspot: undefined,
                                                open: false,
                                            }),
                                        );
                                    }}
                                />
                                <CancelRounded
                                    sx={{
                                        marginTop: "10px",
                                    }}
                                    color={"error"}
                                    onClick={() => {
                                        setCurrentTimeEditInput(videoCurrentTime);
                                        setCurrentTimeEditing(false);
                                        dispatch(
                                            setCurrentHotspot({
                                                currentHotspot: undefined,
                                                open: false,
                                            }),
                                        );
                                    }}
                                />
                                <SaveRounded
                                    sx={{
                                        marginTop: "10px",
                                    }}
                                    color={"success"}
                                    type={"submit"}
                                    onClick={() => {
                                        video.pause();
                                        video.currentTime = currentTimeEditInput;
                                        getNextQuestion(currentTimeEditInput);
                                        setVideoCurrentState("Paused");
                                        setCurrentTimeEditing(false);
                                        dispatch(
                                            setCurrentHotspot({
                                                currentHotspot: undefined,
                                                open: false,
                                            }),
                                        );
                                    }}
                                />
                            </form>
                        </>
                    ) : (
                        <div
                            style={{
                                fontFamily: "Digit",
                                fontSize: "0.9em",
                            }}
                            // TODO; re-enable time seeking
                            // onClick={() => {
                            //     video.pause();
                            //     setVideoCurrentState("Paused");
                            //     setCurrentTimeEditInput(videoCurrentTime);
                            //     setCurrentTimeEditing(true);
                            // }}
                        >
                            {getTimeElapsedInStopWatchFormatString(videoCurrentTime * 1000)}
                        </div>
                    )}

                    <span
                        style={{
                            fontSize: "1.3",
                            marginLeft: "6px",
                            marginRight: "2px",
                            marginTop: "-1px",
                        }}
                    >
                        /
                    </span>

                    <div style={{ fontFamily: "Digit", fontSize: "0.9em" }}>
                        {video.duration ? getTimeElapsedInStopWatchFormatString(video.duration * 1000) : "00:00:00"}
                    </div>
                </Grid>

                {video && currentlyMuted ? (
                    <VTButton
                        onClick={() => {
                            video.muted = false;
                            localStorage.setItem("video_muted", "false");
                            setCurrentMuted(false);
                        }}
                        sx={{ marginLeft: "15px" }}
                        type={"icon"}
                    >
                        <VolumeOffRounded />
                    </VTButton>
                ) : (
                    <VTButton
                        onClick={() => {
                            video.muted = true;
                            localStorage.setItem("video_muted", "true");
                            setCurrentMuted(true);
                        }}
                        sx={{ marginLeft: "15px" }}
                        type={"icon"}
                    >
                        <VolumeUpRounded />
                    </VTButton>
                )}

                {!currentlyPreview && videoNextQuestion && videoCurrentState === "Question" && (
                    <VTButton
                        sx={{
                            marginLeft: "30px",
                        }}
                        disabled={videoNextQuestion.id.includes("demo")}
                        onClick={() => {
                            dispatch(
                                setCurrentSidePanel({
                                    currentTab: undefined,
                                    currentWidth: 0,
                                    option: undefined,
                                }),
                            );
                            setEditStopPointTime(Math.round(videoCurrentTime * 100) / 100);
                            setEditStopPointTimeOpen(true);
                        }}
                    >
                        Edit Time
                    </VTButton>
                )}
                {!currentlyPreview && (
                    <>
                        {videoNextQuestion && videoCurrentState === "Question" ? (
                            <VTButton
                                sx={{
                                    marginLeft: "30px",
                                }}
                                disabled={videoNextQuestion.id.includes("demo")}
                                onClick={() => {
                                    getNextQuestion(videoCurrentTime + 0.03);
                                    if (sortedQuestions.length === 1) {
                                        setTimelineMode("Scenes");
                                    }
                                    setSortedQuestions(
                                        sortedQuestions.filter(
                                            (filterElement: IHotspot) => filterElement.id !== videoNextQuestion.id,
                                        ),
                                    );
                                    setVideoCurrentState("Paused");
                                    deleteHotspot({ sceneId: sceneId!, hotspotId: videoNextQuestion.id });

                                    if (
                                        // sortedQuestions.length > 0 &&
                                        hotspots.filter(
                                            (hotspotFilterElement: IHotspot) =>
                                                hotspotFilterElement.type === EDraggableOptions.QUESTION,
                                        ).length === 1
                                    ) {
                                        updateScene({ ...currentScene, hasStopPoints: false });
                                    }
                                }}
                            >
                                <DeleteRounded sx={{ marginRight: "10px" }} />
                                Delete Stop Point
                            </VTButton>
                        ) : (
                            <Tooltip
                                title={
                                    videoCurrentTime <= 0.1
                                        ? "Please let the video play before creating a stop point"
                                        : "Please increase the time between stop points to create"
                                }
                                disableHoverListener={
                                    !(
                                        videoCurrentTime <= 0.3 ||
                                        (videoNextQuestion
                                            ? videoCurrentTime >=
                                              videoNextQuestion.contents.preClickContent.question!.time! - 0.2
                                            : false) ||
                                        (previousQuestionTime ? videoCurrentTime <= previousQuestionTime! + 0.2 : false)
                                    )
                                }
                            >
                                <Grid item>
                                    <VTButton
                                        sx={{ marginLeft: "30px" }}
                                        onClick={() => {
                                            video.pause();
                                            setVideoTimelineLoading("createStopPoint");
                                            setPreviousQuestionTime(videoCurrentTime);
                                            const newStopPoint = blankQuestionHotspot(videoCurrentTime);
                                            createHotspot({ sceneId: sceneId!, hotspot: newStopPoint });
                                            dispatch(setCurrentQuestion(newStopPoint));
                                            setVideoNextQuestion(newStopPoint);
                                            setVideoCurrentState("Question");
                                            dispatch(
                                                setCurrentHotspot({
                                                    currentHotspot: undefined,
                                                    open: false,
                                                }),
                                            );
                                            setTimelineMode("Timeline");

                                            if (
                                                // sortedQuestions.length > 0 &&
                                                hotspots.filter(
                                                    (hotspotFilterElement: IHotspot) =>
                                                        hotspotFilterElement.type === EDraggableOptions.QUESTION,
                                                ).length === 0
                                            ) {
                                                // console.log("setting hasStopPoints = true");
                                                updateScene({ ...currentScene, hasStopPoints: true });
                                            }
                                        }}
                                        disabled={
                                            videoCurrentTime <= 0.5 ||
                                            (videoNextQuestion
                                                ? videoCurrentTime >=
                                                      videoNextQuestion.contents.preClickContent.question!.time! -
                                                          0.2 &&
                                                  videoCurrentTime <
                                                      videoNextQuestion.contents.preClickContent.question!.time!
                                                : false) ||
                                            (previousQuestionTime
                                                ? videoCurrentTime <= previousQuestionTime! + 0.2 &&
                                                  videoCurrentTime > previousQuestionTime!
                                                : false)
                                        }
                                    >
                                        <AddRounded sx={{ marginRight: "10px" }} /> Add Stop Point
                                    </VTButton>
                                </Grid>
                            </Tooltip>
                        )}
                    </>
                )}
            </Grid>

            <VTDialog
                open={editStopPointTimeOpen}
                handleClose={() => setEditStopPointTimeOpen(false)}
                title={"Change the time of a stop point"}
                sx={{ marginLeft: ` ${100 + currentSidePanel.currentWidth}px ` }}
                primaryArea={
                    <Grid container justifyContent={"flex-end"}>
                        <VTButton onClick={() => setEditStopPointTimeOpen(false)} sx={{ marginRight: "10px" }}>
                            Cancel
                        </VTButton>
                        <Tooltip
                            title={"This time is too close to another stop point please change it to a sutible time"}
                            disableHoverListener={!editStopPointTimeDisabled}
                        >
                            <Grid item>
                                <VTButton
                                    onClick={() => {
                                        updateHotspot({
                                            id: videoNextQuestion!.id,
                                            contents: {
                                                ...videoNextQuestion!,
                                                preClickContent: {
                                                    ...videoNextQuestion!.contents.preClickContent,
                                                    question: {
                                                        ...videoNextQuestion!.contents.preClickContent.question,

                                                        time: editStopPointTime,
                                                        answers: [],
                                                    },
                                                },
                                            },
                                        });
                                        video.currentTime = editStopPointTime;
                                        setVideoCurrentTime(editStopPointTime);
                                        dispatch(
                                            setCurrentHotspot({
                                                currentHotspot: undefined,
                                                open: false,
                                            }),
                                        );
                                        setEditStopPointTimeOpen(false);
                                    }}
                                    disabled={editStopPointTimeDisabled}
                                >
                                    Save
                                </VTButton>
                            </Grid>
                        </Tooltip>
                    </Grid>
                }
            >
                <Grid
                    sx={{ width: "100%", height: "100%" }}
                    justifyContent={"center"}
                    alignContent={"center"}
                    container
                >
                    <TextField
                        sx={{ margin: "30px" }}
                        autoFocus
                        type={"number"}
                        value={editStopPointTime}
                        onChange={(e: any) => {
                            setEditStopPointTime(parseFloat(e.target.value));
                        }}
                    />
                </Grid>
            </VTDialog>
        </Grid>
    );
}
