//---------------------------------------------------------------------------------------------------
// ## IMPORTS ##
// Library Imports
import { useRef, useState, useEffect } from "react";
import { v4 as uuid } from "uuid";
// Material ui Imports
import {
    CircularProgress,
    Grid,
    ImageList,
    ImageListItem,
    LinearProgress,
    Skeleton,
    SxProps,
    Tooltip,
    Typography,
    useTheme,
} from "@mui/material";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import NoVideos from "../../assets/images/NoVideos.svg";
// Custom components imports (and hooks and helpers)
import {
    VTButton,
    VTSidePanelVideo2dItem,
    VTTextField,
    VTTypography,
} from "@virtus-tech-repository/virtus-tech-repository";
import { EDraggableOptions, IMediaItem } from "@virtus-tech-repository/virtus-tech-repository/lib/models/media.model";
import { Preview } from "@mui/icons-material";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
    useCreateAllVideosMutation,
    useDeleteMediaMutation,
    useGetAllVideos2dQuery,
    usePreSignedUrlMutation,
    useUpdateAllScenarioMediaMutation,
} from "../../services/media.service";
import { setMediaUploadCleanUp } from "../../store/slices/mediaUpload.slice";
import { uploadMedia } from "../../store/thunks/upload.thunks";
import VTVideoDraggable from "./VTVideoDraggable";
import { Params, useParams } from "react-router-dom";
import SearchNotFound from "../../assets/images/NoVideos.svg";
import { moveCursorToEnd } from "../../utils/moveCursorToEndRichTextEditor";
import { IDragOptions } from "@virtus-tech-repository/virtus-tech-repository/lib/models/current.model";
import { setCurrentDoubleClick } from "../../store/slices/current.slice";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { EDroppableContainerId } from "@virtus-tech-repository/virtus-tech-repository/lib/models/DragAndDrop.models";
import VTMediaUploader from "./VTMediaUploader";
import WordingHelper from "../../helpers/wordingHelper";
// Media imports

//---------------------------------------------------------------------------------------------------
// ## MUI STYLED ##
// Layout stuff remains inline, other styling pulled into it's own components
//---------------------------------------------------------------------------------------------------
// ## INTERFACES ##
interface IProps {
    sx?: SxProps;
    handleDragStart: any;
    handleDragStop: () => void;
    videoCurrentState?: "Paused" | "Playing" | "Question" | undefined;
}

//---------------------------------------------------------------------------------------------------
// ## COMPONENTS ##
// Only display layouts in pages
export default function VTImmersiveVideoSidePanel({ handleDragStart, handleDragStop, sx, videoCurrentState }: any) {
    //-------------------------------------------------------------------------------------------------
    // ## HOOKS ##
    const inputFileRef = useRef<HTMLInputElement>(null);
    const { scenarioId, sceneId }: Readonly<Params<string>> = useParams();

    const { id: userId, organisation } = useAppSelector((state) => state.userReducer);

    const [preSignedUrl, { data: uploadUrl, isLoading: preSignedUrlLoading }] = usePreSignedUrlMutation();

    const { mediaUploadPercentage, mediaUploadCompleted, mediaUploadError, mediaUploading } = useAppSelector(
        (state) => state.mediaUploadReducer,
    );

    const [createAllVideos] = useCreateAllVideosMutation();

    const [selectedVideo, setSelectedVideo] = useState<File | null>(null);
    const [selectedVideoError, setSelectedVideoError] = useState<boolean>(false);
    const [videoName, setVideoName] = useState<string>("");
    const [videoId, setVideoId] = useState<string>(uuid());

    const [highlight, setHighlight] = useState(false);

    const { data: videos, isLoading: videosLoading } = useGetAllVideos2dQuery(userId);
    const { data: scenarioVideos, isLoading: scenarioVideosLoading } = useGetAllVideos2dQuery(scenarioId!);

    const dispatch = useAppDispatch();
    const [updateAllScenarioMedia, { isLoading: updateLoading }] = useUpdateAllScenarioMediaMutation();
    const [deleteAllScenarioMedia, { isLoading: deleteLoading }] = useDeleteMediaMutation();

    const [currentItemUpdate, setCurrentItemUpdate] = useState<string | undefined>(undefined);

    const theme = useTheme();

    //-------------------------------------------------------------------------------------------------
    // ## USE SELECTOR ##

    const { currentQuestion, editHotspotDialogIsCurrentlyOpen, currentlyDragging } = useAppSelector(
        (state) => state.currentReducer,
    );
    //-------------------------------------------------------------------------------------------------
    // ## USE STATE ##

    //-------------------------------------------------------------------------------------------------
    // ## USE EFFECTS ##
    useEffect(() => {
        if (mediaUploadCompleted && selectedVideo) {
            createAllVideos({
                id: userId,
                media_id: videoId,
                media_type: "video",
                filetype: selectedVideo.type.split("/")[1],
                name: videoName,
                description: "",
                date: Date.now(),
                tags: [],
                preview: false,
            });
        }
    }, [mediaUploadCompleted]);

    //-------------------------------------------------------------------------------------------------
    // ## CUSTOM FUNCTIONS ##
    const [activeButton, setActiveButton] = useState(0);
    const [searchValue, setSearchValue] = useState<string>("");

    const handleButtonClick = (buttonIndex: number) => {
        setActiveButton(buttonIndex);
    };

    function preventDefaults(e: React.DragEvent) {
        e.preventDefault();
        e.stopPropagation();
    }

    async function handleFileAdded(videoFile: File) {
        // TODO: in the future only allow certain video types
        if (videoFile.type.includes("video/")) {
            setSelectedVideoError(false);
            setSelectedVideo(videoFile);
            setVideoName(videoFile.name.split(".")[0]);

            // Get presigned url to upload video
            await preSignedUrl({
                organisation,
                media_type: "video",
                media_id: `${videoId}`,
                filetype: videoFile.type.split("/")[1],
            });
        } else {
            setSelectedVideoError(true);
        }
    }

    // Function that handle video added from dropping and dragging in
    function handleDrop(e: React.DragEvent) {
        preventDefaults(e);
        setHighlight(false);

        if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
            handleFileAdded(e.dataTransfer.files[0]);
        }
    }

    // Function that handle video added from file system
    function handleSelect(e: React.ChangeEvent<HTMLInputElement>) {
        setHighlight(false);

        if (e.target.files && e.target.files.length > 0) {
            handleFileAdded(e.target.files[0]);
        }
    }

    function handleVideoUpload() {
        if (uploadUrl && selectedVideo) {
            dispatch(
                uploadMedia({
                    media: selectedVideo,
                    url: uploadUrl.url,
                    dispatch: dispatch,
                }),
            );
        }
    }

    function handleUploadCleanUp() {
        setSelectedVideo(null);
        setVideoId(uuid());
        setVideoName("");
        dispatch(setMediaUploadCleanUp());
        setActiveButton(0);
    }

    function handleDoubleClick(mediaType: EDraggableOptions, src: string) {
        // console.log("handle double click");

        // console.log("media type", mediaType);
        // console.log("src", src);

        if (editHotspotDialogIsCurrentlyOpen) {
            handleDragStart(mediaType, src);

            window.setTimeout(() => {
                moveCursorToEnd("rich-text-editor");
                document.execCommand("insertHTML", false, `<video id="video-drop-img" />`);
                moveCursorToEnd("rich-text-editor");
            }, 300);

            // 400ms after
            window.setTimeout(() => {
                handleDragStop();
            }, 2000);
        }

        dispatch(setCurrentDoubleClick({ src: src, mediaType: mediaType }));
    }

    //-------------------------------------------------------------------------------------------------
    // ## RETURN ##
    // Show available Videos

    return (
        <Grid
            container
            xs={12}
            justifyContent={"center"}
            // alignItems={'flex-start'}
            sx={{ overflowY: "hidden", userSelect: "none", ...sx }}
        >
            {/*-----------------------------------------------------------------------------------------*/}
            {/* Video search */}
            <Grid item xs={11}>
                <VTTextField
                    placeholder={"Search Videos"}
                    sx={{
                        marginTop: "20px",
                    }}
                    value={searchValue}
                    onChange={(textInput: string) => setSearchValue(textInput)}
                    type={"text"}
                    endIcon={<SearchRoundedIcon />}
                />
            </Grid>

            {/*-----------------------------------------------------------------------------------------*/}
            {/* Filters */}
            <Grid
                item
                container
                xs={11}
                sx={{
                    marginTop: "15px",
                    // display: "flex",
                    // overflowX: "auto",
                }}
            >
                <Grid item xs={4}>
                    <VTButton
                        onClick={() => handleButtonClick(0)}
                        sx={{
                            borderBottom: activeButton === 0 ? `3px solid ${theme.palette.primary.main}` : "none",
                            maxWidth: "100%",
                        }}
                        type="underline"
                    >
                        <Typography>All Videos</Typography>
                    </VTButton>
                </Grid>
                <Grid item xs={4}>
                    {scenarioVideos && (
                        <VTButton
                            onClick={() => handleButtonClick(2)}
                            sx={{
                                borderBottom: activeButton === 2 ? `3px solid ${theme.palette.primary.main}` : "none",
                                maxWidth: "100%",
                            }}
                            type="underline"
                        >
                            <Typography>{WordingHelper(organisation)} Videos</Typography>
                        </VTButton>
                    )}
                </Grid>
                <Grid item xs={4}>
                    <VTButton
                        onClick={() => handleButtonClick(1)}
                        sx={{
                            borderBottom: activeButton === 1 ? `3px solid ${theme.palette.primary.main}` : "none",
                            maxWidth: "100%",
                        }}
                        type="underline"
                    >
                        <Typography>Upload Videos</Typography>
                    </VTButton>
                </Grid>
            </Grid>

            {activeButton === 0 && (
                <>
                    {/*-----------------------------------------------------------------------------------------*/}
                    {/* Video list */}

                    {videosLoading ? (
                        <Grid
                            item
                            container
                            xs={12}
                            columnSpacing={1}
                            alignContent={"flex-start"}
                            sx={{
                                marginTop: "5px",
                                overflowY: "scroll",
                                width: "100%",
                                padding: "20px",
                            }}
                        >
                            <Grid item xs={6} sx={{ marginBottom: "8px" }}>
                                <VTSidePanelVideo2dItem sx={{ width: "100%" }} loading name={""} imgSrc={""} />
                            </Grid>
                            <Grid item xs={6} sx={{ marginBottom: "8px" }}>
                                <VTSidePanelVideo2dItem sx={{ width: "100%" }} loading name={""} imgSrc={""} />
                            </Grid>
                            <Grid item xs={6} sx={{ marginBottom: "8px" }}>
                                <VTSidePanelVideo2dItem sx={{ width: "100%" }} loading name={""} imgSrc={""} />
                            </Grid>
                            <Grid item xs={6} sx={{ marginBottom: "8px" }}>
                                <VTSidePanelVideo2dItem sx={{ width: "100%" }} loading name={""} imgSrc={""} />
                            </Grid>
                        </Grid>
                    ) : (
                        <>
                            {/* @ts-ignore */}
                            {!videos || videos.length === 0 ? (
                                <Grid item xs={12} textAlign={"center"}>
                                    <img src={NoVideos} style={{ width: "90%", marginTop: "20px" }} />
                                    <VTTypography sx={{ color: "grey", padding: "10px" }}>
                                        To get started, add first video file
                                    </VTTypography>
                                </Grid>
                            ) : (
                                <Grid
                                    item
                                    container
                                    sx={{
                                        // marginTop: "20px",
                                        padding: "20px",
                                        overflowY: "auto",
                                        overflowX: "hidden",
                                        height: "calc(100vh - 225px)",
                                        width: "100%",
                                    }}
                                >
                                    {videos?.filter(
                                        (item: IMediaItem) =>
                                            searchValue === "" ||
                                            item.name!.toLowerCase().includes(searchValue.toLocaleLowerCase()) ||
                                            item.description!.toLowerCase().includes(searchValue.toLocaleLowerCase()),
                                    ).length === 0 ? (
                                        <Grid item xs={12} textAlign={"center"}>
                                            <img src={SearchNotFound} style={{ width: "90%", marginTop: "20px" }} />
                                            <Typography sx={{ color: "grey", padding: "10px" }}>
                                                No matches found
                                            </Typography>
                                        </Grid>
                                    ) : (
                                        <Droppable
                                            droppableId={EDroppableContainerId.MEDIA_2D_VIDEOS}
                                            type="DROPPABLE_MEDIA"
                                            // isDropDisabled={false}
                                            // renderClone={(provided, snapshot, rubric) => (
                                            //     <div
                                            //         {...provided.draggableProps}
                                            //         {...provided.dragHandleProps}
                                            //         ref={provided.innerRef}
                                            //     >
                                            //         Item id: {images[rubric.source.index].id}
                                            //     </div>
                                            // )}
                                        >
                                            {(provided, snapshot) => (
                                                <Grid
                                                    ref={provided.innerRef}
                                                    {...provided.droppableProps}
                                                    item
                                                    container
                                                    xs={12}
                                                    columnSpacing={1}
                                                    sx={{
                                                        // background: "blue",
                                                        marginTop: "5px",
                                                        // overflowY: "scroll",
                                                        width: "100%",
                                                        // height: "100%"
                                                        // padding: "20px",
                                                        // height: "calc(100vh - 230px)",
                                                    }}
                                                    alignContent={"flex-start"}
                                                >
                                                    {videos
                                                        ?.filter(
                                                            (item: IMediaItem) =>
                                                                searchValue === "" ||
                                                                item
                                                                    .name!.toLowerCase()
                                                                    .includes(searchValue.toLocaleLowerCase()) ||
                                                                item
                                                                    .description!.toLowerCase()
                                                                    .includes(searchValue.toLocaleLowerCase()),
                                                        )
                                                        .sort((a: IMediaItem, b: IMediaItem) => {
                                                            return (
                                                                new Date(b.date).valueOf() - new Date(a.date).valueOf()
                                                            );
                                                        })
                                                        .map((item: IMediaItem, i: number) => (
                                                            // <VTVideoDraggable
                                                            //     item={item}
                                                            //     organisation={organisation}
                                                            //     localHandleDrag={localHandleDrag}
                                                            //     handleDoubleClick={handleDoubleClick}
                                                            // />
                                                            <Draggable
                                                                disableInteractiveElementBlocking={true}
                                                                key={item.id}
                                                                draggableId={`${process.env.REACT_APP_NINJA_MEDIA}/${organisation}/video/${item.id}.${item.filetype}`}
                                                                index={i}
                                                            >
                                                                {(provided, snapshot) => (
                                                                    <Grid
                                                                        item
                                                                        key={item.id}
                                                                        xs={6}
                                                                        sx={{
                                                                            marginBottom: "8px",
                                                                            // padding: "5px",
                                                                            // height: "200px",
                                                                            position: "relative",
                                                                        }}
                                                                    >
                                                                        <VTSidePanelVideo2dItem
                                                                            onHandleDoubleClick={() => {
                                                                                handleDoubleClick(
                                                                                    EDraggableOptions.VIDEO,
                                                                                    `${process.env.REACT_APP_NINJA_MEDIA}/${organisation}/video/${item.id}.${item.filetype}`,
                                                                                );
                                                                            }}
                                                                            sx={{
                                                                                transform:
                                                                                    !snapshot.isDragging ||
                                                                                    (currentlyDragging &&
                                                                                        !snapshot.isDragging)
                                                                                        ? "none !important"
                                                                                        : "auto",
                                                                                ...(snapshot.isDragging && {
                                                                                    border: `4px dashed ${theme.palette.primary.light}`,
                                                                                    borderRadius: "16px",
                                                                                }),
                                                                            }}
                                                                            {...provided.draggableProps}
                                                                            {...provided.dragHandleProps}
                                                                            ref={provided.innerRef}
                                                                            name={item.name!}
                                                                            imgSrc={`${process.env.REACT_APP_NINJA_MEDIA}/${organisation}/video/${item.id}/${item.id}.${item.filetype}`}
                                                                        />
                                                                        {snapshot.isDragging && (
                                                                            <div
                                                                                style={{
                                                                                    backgroundColor:
                                                                                        theme.palette.background.paper,
                                                                                    width: "100%",
                                                                                    height: "150px",
                                                                                    borderRadius: "15px",
                                                                                    transform: "none !important",
                                                                                }}
                                                                            ></div>
                                                                        )}
                                                                    </Grid>
                                                                )}
                                                            </Draggable>
                                                        ))}
                                                    {provided.placeholder}
                                                </Grid>
                                            )}
                                        </Droppable>
                                    )}
                                </Grid>
                            )}
                        </>
                    )}
                </>
            )}

            {/*-----------------------------------------------------------------------------------------*/}
            {/* Video upload */}
            {activeButton === 1 && (
                <VTMediaUploader
                    context={"Videos"}
                    onComplete={() => setActiveButton(0)}
                    sx={{ overflowX: "auto", height: "calc(100vh - 224px)" }}
                />
            )}

            {activeButton === 2 && (
                <>
                    {" "}
                    {/*-----------------------------------------------------------------------------------------*/}
                    {/* Video list */}
                    {scenarioVideosLoading ? (
                        <Grid
                            item
                            container
                            xs={12}
                            columnSpacing={1}
                            alignContent={"flex-start"}
                            sx={{
                                marginTop: "5px",
                                overflowY: "scroll",
                                width: "100%",
                                padding: "20px",
                            }}
                        >
                            <Grid item xs={6} sx={{ marginBottom: "8px" }}>
                                <VTSidePanelVideo2dItem sx={{ width: "100%" }} loading name={""} imgSrc={""} />
                            </Grid>
                            <Grid item xs={6} sx={{ marginBottom: "8px" }}>
                                <VTSidePanelVideo2dItem sx={{ width: "100%" }} loading name={""} imgSrc={""} />
                            </Grid>
                            <Grid item xs={6} sx={{ marginBottom: "8px" }}>
                                <VTSidePanelVideo2dItem sx={{ width: "100%" }} loading name={""} imgSrc={""} />
                            </Grid>
                            <Grid item xs={6} sx={{ marginBottom: "8px" }}>
                                <VTSidePanelVideo2dItem sx={{ width: "100%" }} loading name={""} imgSrc={""} />
                            </Grid>
                        </Grid>
                    ) : (
                        <>
                            {!scenarioVideos || scenarioVideos.length === 0 ? (
                                <Grid item xs={12} textAlign={"center"}>
                                    <img src={NoVideos} style={{ width: "90%", marginTop: "20px" }} />
                                    <VTTypography sx={{ color: "grey", padding: "10px" }}>
                                        To get started, add first video file to the scenario
                                    </VTTypography>
                                </Grid>
                            ) : (
                                <Grid
                                    item
                                    container
                                    sx={{
                                        padding: "20px",
                                        overflowY: "auto",
                                        overflowX: "hidden",
                                        height: "calc(100vh - 225px)",
                                        width: "100%",
                                    }}
                                >
                                    {scenarioVideos?.filter(
                                        (item: IMediaItem) =>
                                            searchValue === "" ||
                                            item.name!.toLowerCase().includes(searchValue.toLocaleLowerCase()) ||
                                            item.description!.toLowerCase().includes(searchValue.toLocaleLowerCase()),
                                    ).length === 0 ? (
                                        <Grid item xs={12} textAlign={"center"}>
                                            <img src={SearchNotFound} style={{ width: "90%", marginTop: "20px" }} />
                                            <Typography sx={{ color: "grey", padding: "10px" }}>
                                                No matches found
                                            </Typography>
                                        </Grid>
                                    ) : (
                                        <Grid
                                            item
                                            container
                                            xs={12}
                                            columnSpacing={1}
                                            sx={{
                                                // background: "blue",
                                                marginTop: "5px",
                                                // overflowY: "scroll",
                                                width: "100%",
                                                // height: "100%"
                                                // padding: "20px",
                                                // height: "calc(100vh - 230px)",
                                            }}
                                            alignContent={"flex-start"}
                                        >
                                            {scenarioVideos
                                                ?.filter(
                                                    (item: IMediaItem) =>
                                                        searchValue === "" ||
                                                        item
                                                            .name!.toLowerCase()
                                                            .includes(searchValue.toLocaleLowerCase()) ||
                                                        item
                                                            .description!.toLowerCase()
                                                            .includes(searchValue.toLocaleLowerCase()),
                                                )
                                                .sort((a: IMediaItem, b: IMediaItem) => {
                                                    return new Date(b.date).valueOf() - new Date(a.date).valueOf();
                                                })
                                                .map((item: IMediaItem) => (
                                                    // <>
                                                    //     {(updateLoading || deleteLoading) &&
                                                    //     currentItemUpdate == item.id ? (
                                                    //         <Grid item xs={6}>
                                                    //             <Skeleton
                                                    //                 sx={{
                                                    //                     height: "300px",
                                                    //                     margin: "10px",
                                                    //                     marginBlock: "-25px",
                                                    //                     marginTop: "-55px",
                                                    //                 }}
                                                    //             />
                                                    //         </Grid>
                                                    //     ) : (
                                                    //         <VTVideoDraggable
                                                    //             key={item.id}
                                                    //             item={item}
                                                    //             organisation={organisation}
                                                    //             localHandleDrag={localHandleDrag}
                                                    //             handleDoubleClick={handleDoubleClick}
                                                    // addFunction={() => {
                                                    //     setCurrentItemUpdate(item.id);
                                                    //     updateAllScenarioMedia({
                                                    //         id: userId,
                                                    //         media_type: EDraggableOptions.VIDEO,
                                                    //         media_ids: [item.id],
                                                    //     });
                                                    // }}
                                                    //             deleteFunction={() => {
                                                    //                 setCurrentItemUpdate(item.id);
                                                    //                 deleteAllScenarioMedia({
                                                    //                     mediaType: EDraggableOptions.VIDEO,
                                                    //                     collectionId: scenarioId!,
                                                    //                     mediaId: item.id,
                                                    //                 });
                                                    //             }}
                                                    //         />
                                                    //     )}
                                                    // </>
                                                    <Grid
                                                        item
                                                        key={item.id}
                                                        xs={6}
                                                        sx={{
                                                            marginBottom: "8px",
                                                            // padding: "5px",
                                                            // height: "200px",
                                                            position: "relative",
                                                        }}
                                                    >
                                                        <VTSidePanelVideo2dItem
                                                            loading={updateLoading && currentItemUpdate === item.id}
                                                            nameCutOffPoint={12}
                                                            name={item.name!}
                                                            imgSrc={`${process.env.REACT_APP_NINJA_MEDIA}/${organisation}/video/${item.id}/${item.id}.${item.filetype}`}
                                                            onAddButtonClicked={() => {
                                                                setCurrentItemUpdate(item.id);
                                                                updateAllScenarioMedia({
                                                                    id: userId,
                                                                    media_type: EDraggableOptions.VIDEO,
                                                                    media_ids: [item.id],
                                                                });
                                                            }}
                                                        />
                                                    </Grid>
                                                ))}
                                        </Grid>
                                    )}
                                </Grid>
                            )}
                        </>
                    )}
                </>
            )}
        </Grid>
    );
}
