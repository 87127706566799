//---------------------------------------------------------------------------------------------------
// ## IMPORTS ##
// Library Imports

// Material ui Imports

// Custom components imports (and hooks and helpers)
import { VTButton, VTSidePanel } from "@virtus-tech-repository/virtus-tech-repository";
import HomeRouteSidePanel from "../components/HomeRouteSidePanel";
import { IMediaItem } from "@virtus-tech-repository/virtus-tech-repository/lib/models/media.model";
import VTPanel from "../components/VTPanel";
// Media imports
import MenuIcon from "@mui/icons-material/Menu";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { setCurrentSidePanel } from "../../store/slices/current.slice";
import { useDispatch } from "react-redux";
import VTImageSidePanel from "../components/VTImageSidePanel";
import KeyboardArrowRightRoundedIcon from "@mui/icons-material/KeyboardArrowRightRounded";
import { Paper, Tooltip, useTheme } from "@mui/material";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { IDragOptions } from "@virtus-tech-repository/virtus-tech-repository/lib/models/current.model";
import { IScenarioOverview } from "@virtus-tech-repository/virtus-tech-repository/lib/models/scenario.model";
// ## MUI STYLED ##
// Layout stuff remains inline, other styling pulled into it's own components

//---------------------------------------------------------------------------------------------------
// ## INTERFACES ##
// Optional variables towards the bottom
interface IProps {
    images: IMediaItem[] | undefined;
    scenarioDialog: IScenarioOverview | undefined;
    setCurrentPreviewImage: (currentPreviewImage: string) => void;
}

//---------------------------------------------------------------------------------------------------
// ## COMPONENTS ##
export default function HomeSidePanelContainer({ images, scenarioDialog, setCurrentPreviewImage }: IProps) {
    //-------------------------------------------------------------------------------------------------
    // ## HOOKS ##
    const dispatch = useDispatch();
    const theme = useTheme();

    const { currentSidePanel, currentSidePannelDesiredWidth } = useAppSelector((state) => state.currentReducer);

    //-------------------------------------------------------------------------------------------------
    // ## USE SELECTOR ##

    //-------------------------------------------------------------------------------------------------
    // ## USE STATE ##

    //-------------------------------------------------------------------------------------------------
    // ## USE EFFECTS ##

    //-------------------------------------------------------------------------------------------------
    // ## CUSTOM FUNCTIONS ##
    function handleImageDoubleClick(Drag: IDragOptions) {
        setCurrentPreviewImage(Drag.src);
    }

    //-------------------------------------------------------------------------------------------------
    // ## RETURN ##
    // call relevant data from api and get from store
    return (
        <>
            {/*-------------------------------------------------------------------------------------*/}
            {/* Image side panel; for adding preview images to the scenario dialog */}

            {scenarioDialog ? (
                <Paper
                    sx={{
                        zIndex: 1330,
                        position: "absolute",
                        top: "68px",
                        left: currentSidePanel.currentWidth
                            ? 0
                            : scenarioDialog
                            ? -currentSidePanel.currentWidth
                            : -200,
                        bottom: 0,
                        borderRadius: 0,
                        width: scenarioDialog ? currentSidePanel.currentWidth : 200,
                        // transition: "0.5s",
                        backgroundColor: `#2f3032`,
                    }}
                >
                    {currentSidePanel.currentWidth === 0 ? (
                        <VTButton
                            type={"icon"}
                            toolTipOptions={{
                                toolTipPlacement: "right",
                                toolTipText: "Expand",
                                
                            }}
                            sx={{
                                position: "absolute",
                                top: "5px",
                                // left: currentSidePannelDesiredWidth,
                                width: "30px",
                                height: "30px",
                                borderRadius: "0px 15px 15px 0px",
                                zIndex: 10,
                                fontSize: "0.3em",
                                fontWeight: "bold",
                                backgroundColor: `${theme.palette.background.paper}`,
                                ":hover": {
                                    cursor: "pointer",
                                    backgroundColor: "#646567",

                                },
                            }}
                            onClick={() =>
                                dispatch(
                                    setCurrentSidePanel({
                                        currentTab: "Images",
                                        currentWidth: currentSidePannelDesiredWidth,
                                        option: undefined,
                                    }),
                                )
                            }
                        >
                            <KeyboardArrowRightRoundedIcon sx={{ fontSize: "3.5em", marginLeft: "-4px" }} />
                        </VTButton>
                    ) : (
                        <VTButton
                            type={"icon"}
                            toolTipOptions={{
                                toolTipPlacement: "top-end",
                                toolTipText: "Collapse",
                            }}
                            sx={{
                                position: "absolute",
                                left: currentSidePannelDesiredWidth,
                                top: "5.5px",
                                width: "30px",
                                height: "30px",
                                borderRadius: "0px 15px 15px 0px",
                                zIndex: 10,
                                fontSize: "0.3em",
                                backgroundColor: `${theme.palette.background.paper}`,
                                ":hover": {
                                    left: currentSidePannelDesiredWidth,
                                    cursor: "pointer",
                                    backgroundColor: "#646567",

                                },
                            }}
                            onClick={() =>
                                dispatch(
                                    setCurrentSidePanel({
                                        currentTab: undefined,
                                        currentWidth: 0,
                                        option: undefined,
                                    }),
                                )
                            }
                        >
                            <CloseRoundedIcon sx={{ fontSize: "3.5em", marginLeft: "-4px" }} />
                        </VTButton>
                    )}
                    <VTImageSidePanel
                        onHandleDoubleClickSetScenarioImagePreview={setCurrentPreviewImage}
                        sideDrawWidth={currentSidePanel.currentWidth}
                        imagesTitle={currentSidePanel.option ? currentSidePanel.option : ""}
                    />
                </Paper>
            ) : (
                <Paper
                    sx={{
                        zIndex: 1330,
                        position: "absolute",
                        top: "68px",
                        left: currentSidePanel.currentWidth ? 0 : -200,
                        bottom: 0,
                        borderRadius: 0,
                        width: 200,
                        // transition: "0.5s",
                        backgroundColor: `#2f3032`,
                    }}
                >
                    {currentSidePanel.currentWidth === 0 ? (
                        <VTButton
                            type={"icon"}
                            toolTipOptions={{
                                toolTipPlacement: "right",
                                toolTipText: "Expand",
                            }}
                            sx={{
                                position: "absolute",
                                top: "5px",
                                left: "200px",
                                width: "30px",
                                height: "30px",
                                borderRadius: "0px 15px 15px 0px",
                                zIndex: 10,
                                fontSize: "0.3em",
                                fontWeight: "bold",
                                backgroundColor: `${theme.palette.background.paper}`,
                                ":hover": {
                                    cursor: "pointer",
                                    backgroundColor: "#646567",

                                },
                            }}
                            onClick={() =>
                                dispatch(
                                    setCurrentSidePanel({
                                        currentTab: "Routes",
                                        currentWidth: 200,
                                        option: undefined,
                                    }),
                                )
                            }
                        >
                            <KeyboardArrowRightRoundedIcon sx={{ fontSize: "3.5em", marginLeft: "-4px" }} />
                        </VTButton>
                    ) : (
                        <VTButton
                            type={"icon"}
                            toolTipOptions={{
                                toolTipPlacement: "top-end",
                                toolTipText: "Collapse",
                            }}
                            sx={{
                                position: "absolute",
                                left: "200px",
                                top: "5.5px",
                                width: "30px",
                                height: "30px",
                                borderRadius: "0px 15px 15px 0px",
                                zIndex: 10,
                                fontSize: "0.3em",
                                backgroundColor: `${theme.palette.background.paper}`,
                                ":hover": {
                                    cursor: "pointer",
                                    backgroundColor: "#646567",

                                },
                            }}
                            onClick={() =>
                                dispatch(
                                    setCurrentSidePanel({
                                        currentTab: undefined,
                                        currentWidth: 0,
                                        option: undefined,
                                    }),
                                )
                            }
                        >
                            <CloseRoundedIcon sx={{ fontSize: "3.5em", marginLeft: "-4px" }} />
                        </VTButton>
                    )}

                    <HomeRouteSidePanel />
                </Paper>
            )}
        </>
    );
}
