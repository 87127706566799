import {
    VTButton,
    VTCanvas,
    VTCard,
    VTMenu,
    VTTextField,
    VTTypography,
    VTVideoControls,
    useGetMuiBreakPointLabel,
    useGetVTCardTitleCutOffPoint,
} from "@virtus-tech-repository/virtus-tech-repository";
import {
    Box,
    Breakpoint,
    Checkbox,
    CircularProgress,
    FormControlLabel,
    Grid,
    LinearProgress,
    Switch,
    Typography,
    useTheme,
} from "@mui/material";

import { SearchRounded } from "@mui/icons-material";
import FilterListRoundedIcon from "@mui/icons-material/FilterListRounded";
import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";
import CheckBoxOutlineBlankOutlinedIcon from "@mui/icons-material/CheckBoxOutlineBlankOutlined";
import { pink } from "@mui/material/colors";
import { useEffect, useRef, useState } from "react";
import { EDraggableOptions, IMediaItem } from "@virtus-tech-repository/virtus-tech-repository/lib/models/media.model";
import NoVideos from "../../assets/images/NoVideos.svg";
import { useAppDispatch, useAppSelector } from "../../store/hooks";

import { v4 as uuid } from "uuid";
import MyLocationIcon from "@mui/icons-material/MyLocation";
import DownloadIcon from "@mui/icons-material/Download";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DeleteIcon from "@mui/icons-material/Delete";
import {
    useCreateImmersiveVideosMutation,
    usePreSignedUrlMutation,
    useUpdateAllImmersiveVideosMutation,
    useUpdateAllVideosMutation,
} from "../../services/media.service";
import { setMediaUploadCleanUp } from "../../store/slices/mediaUpload.slice";
import { uploadMedia } from "../../store/thunks/upload.thunks";
import VTSphere from "../components/VTSphere";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import UploadIcon from "@mui/icons-material/Upload";
import { ExposedCameraControlFunctions } from "@virtus-tech-repository/virtus-tech-repository/lib/components/VTCanvasComponents/VTCameraControls";
import { setCurrentToast } from "../../store/slices/current.slice";
import { ExposedVTCardRefMethodsObject } from "@virtus-tech-repository/virtus-tech-repository/lib/components/VTCard";
import VTImmersiveVideoUpload from "../components/VTImmersiveVideoUpload";
import VTMediaUploader from "../components/VTMediaUploader";
interface IProps {
    loading: boolean;
    immersiveVideos: IMediaItem[] | undefined;
    leftDrawWidth: number;
    deleteMediaItem: (mediaType: EDraggableOptions, collectionId: string, mediaId: string) => void;
    deleteLoading: boolean;
}

export default function ImmersiveVideosRepoContainer({
    immersiveVideos,
    leftDrawWidth,
    deleteMediaItem,
    deleteLoading,
    loading,
}: IProps) {
    console.log("immersive videos", immersiveVideos);
    //-------------------------------------------------------------------------------------------------
    // ## HOOKS ##
    const cameraControlsRef = useRef<ExposedCameraControlFunctions>(null);
    const inputFileRef = useRef<HTMLInputElement>(null);
    const [vtCardRef, titleCutOffPoint] = useGetVTCardTitleCutOffPoint<HTMLDivElement, ExposedVTCardRefMethodsObject>();
    const theme = useTheme();

    //-------------------------------------------------------------------------------------------------
    // ## USE SELECTOR ##
    const { id: userId, organisation } = useAppSelector((state) => state.userReducer);
    const [preSignedUrl, { data: uploadUrl, isLoading: preSignedUrlLoading }] = usePreSignedUrlMutation();
    const { mediaUploadPercentage, mediaUploadCompleted, mediaUploadError, mediaUploading } = useAppSelector(
        (state) => state.mediaUploadReducer,
    );

    const [createImmersiveVideos] = useCreateImmersiveVideosMutation();
    const [updateAllImmersiveVideos, { isLoading: immersiveVideoUpdateLoading }] =
        useUpdateAllImmersiveVideosMutation();

    const dispatch = useAppDispatch();

    //-------------------------------------------------------------------------------------------------
    // ## USE STATE ##
    const [nameBoxChecked, setNameBoxChecked] = useState<boolean>(true);
    const [tagsBoxChecked, setTagsBoxChecked] = useState<boolean>(false);

    const [filterValue, setFilterValue] = useState<string>("");
    const [changedItem, setChangedItem] = useState<string>("");

    const [video, setVideo] = useState<HTMLVideoElement>(document.createElement("video"));
    const [videoCurrentTime, setVideoCurrentTime] = useState<number>(0);
    const [videoCurrentState, setVideoCurrentState] = useState<"Paused" | "Playing" | "Question" | undefined>(
        undefined,
    );
    const [selectedVideo, setSelectedVideo] = useState<File | null>(null);
    const [selectedVideoError, setSelectedVideoError] = useState<boolean>(false);
    const [videoName, setVideoName] = useState<string>("");
    const [videoId, setVideoId] = useState<string>(uuid());
    const [videoPreview, setVideoPreview] = useState<string | null>(null);
    const [capturePreview, setCapturePreview] = useState<boolean>(false);

    const [checked, setChecked] = useState(false);
    const [highlight, setHighlight] = useState(false);

    //-------------------------------------------------------------------------------------------------
    // ## USE EFFECTS ##
    useEffect(() => {
        if (mediaUploadCompleted && selectedVideo) {
            if (checked) {
                createImmersiveVideos({
                    id: userId,
                    media_id: videoId,
                    media_type: "three_sixty_video",
                    filetype: selectedVideo.type.split("/")[1],
                    name: videoName,
                    description: "",
                    date: Date.now(),
                    tags: [],
                    preview: capturePreview,
                    organisation: organisation,
                    chopping: "processing",
                });
            } else {
                createImmersiveVideos({
                    id: userId,
                    media_id: videoId,
                    media_type: "three_sixty_video",
                    filetype: selectedVideo.type.split("/")[1],
                    name: videoName,
                    description: "",
                    date: Date.now(),
                    tags: [],
                    preview: capturePreview,
                    organisation: organisation,
                });
            }
        }
    }, [mediaUploadCompleted]);

    useEffect(() => {
        if (!deleteLoading) {
            setChangedItem("");
        }
    }, [deleteLoading]);

    //-------------------------------------------------------------------------------------------------
    // ## CUSTOM FUNCTIONS ##
    const [activeButton, setActiveButton] = useState(0);
    const [searchValue, setSearchValue] = useState<string>("");

    function preventDefaults(e: React.DragEvent) {
        e.preventDefault();
        e.stopPropagation();
    }

    async function handleFileAdded(videoFile: File) {
        // TODO: in the future only allow certain videos types
        if (videoFile.type.includes("video/") && ["mp4"].includes(videoFile.name.split(".")[1])) {
            setSelectedVideoError(false);
            setSelectedVideo(videoFile);
            setVideoName(videoFile.name.split(".")[0]);

            if (checked) {
                // Get presigned url to upload video
                await preSignedUrl({
                    organisation,
                    media_type: "three_sixty_video",
                    media_id: `${videoId}/raw`,
                    filetype: videoFile.type.split("/")[1],
                });
            } else {
                // Get presigned url to upload video
                await preSignedUrl({
                    organisation,
                    media_type: "three_sixty_video",
                    media_id: `${videoId}/${videoId}`,
                    filetype: videoFile.type.split("/")[1],
                });
            }
        } else {
            setSelectedVideoError(true);
        }
    }

    async function handleGetPreviewURL() {
        // Get presigned url to upload image
        await preSignedUrl({
            organisation,
            media_type: "three_sixty_video",
            media_id: `${videoId}/${videoId}_preview`,
            filetype: "png",
        });
    }

    // Function that handle image added from dropping and dragging in
    function handleDrop(e: React.DragEvent) {
        preventDefaults(e);
        setHighlight(false);

        if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
            handleFileAdded(e.dataTransfer.files[0]);
        }
    }

    // Function that handle video added from file system
    function handleSelect(e: React.ChangeEvent<HTMLInputElement>) {
        setHighlight(false);

        if (e.target.files && e.target.files.length > 0) {
            handleFileAdded(e.target.files[0]);
        }
    }

    function handleVideoUpload() {
        if (uploadUrl && selectedVideo) {
            dispatch(
                uploadMedia({
                    media: selectedVideo,
                    url: uploadUrl.url,
                    dispatch: dispatch,
                }),
            );
        }
    }

    async function handlePreviewUpload() {
        if (videoPreview) {
            const file = await dataUrlToFile(videoPreview, "preview");

            if (uploadUrl && videoPreview) {
                dispatch(
                    uploadMedia({
                        media: file,
                        url: uploadUrl.url,
                        dispatch: dispatch,
                    }),
                );
            }
        }
    }

    function handleUploadCleanUp() {
        setSelectedVideo(null);
        setVideoId(uuid());
        setVideoName("");
        dispatch(setMediaUploadCleanUp());
        setActiveButton(0);
        setCapturePreview(false);
    }

    async function dataUrlToFile(dataUrl: string, fileName: string): Promise<File> {
        const res: Response = await fetch(dataUrl);
        const blob: Blob = await res.blob();
        return new File([blob], fileName, { type: "image/png" });
    }

    const currentScreenWidthBreakpoint = useGetMuiBreakPointLabel();
    const breakPointsToHideUploadTextOn: Breakpoint[] = ["md", "sm", "xs"];

    const whiteListedIds = ["9de983f7-2be4-4a69-9e46-b69b257b2716"];
    function handleSwitchChange(event: React.ChangeEvent<HTMLInputElement>) {
        setChecked(event.target.checked);

        if (event.target.checked) {
            dispatch(
                setCurrentToast({
                    id: "360_video_process_toast",
                    message: "This will proccess the 360 video metadata and upload for you.",
                }),
            );
        }
    }

    return (
        <Grid container xs={12} alignItems={"flex-start"}>
            <Grid
                item
                container
                xs={12}
                spacing={1}
                sx={{
                    margin: 5,
                    marginTop: "110px",
                    marginLeft: leftDrawWidth / 8 + 5,
                    // transition: "0.5s",
                }}
            >
                <Grid container item sx={{ marginBottom: "25px" }} justifyContent={"space-between"}>
                    {activeButton === 0 && (
                        <Grid item xs={6}>
                            <VTTextField
                                value={filterValue}
                                onChange={(text: string) => setFilterValue(text)}
                                placeholder="Search 360 Videos"
                                endIcon={<SearchRounded />}
                                sx={{ width: "100%", maxWidth: "350px" }}
                            />
                        </Grid>
                    )}
                    {activeButton === 0 && (
                        <Grid container item xs={activeButton === 0 ? 6 : 12} justifyContent={"end"}>
                            <VTButton
                                onClick={() => {
                                    if (activeButton === 0) {
                                        setActiveButton(1);
                                    } else {
                                        setActiveButton(0);
                                    }
                                }}
                                sx={{
                                    borderBottom: `3px solid ${theme.palette.primary.main}`,
                                    maxWidth: "100%",
                                    height: "60px",
                                }}
                                type="underline"
                                endIcon={<UploadIcon sx={{ marginLeft: "10px" }} />}
                            >
                                {!breakPointsToHideUploadTextOn.includes(currentScreenWidthBreakpoint) && (
                                    <Typography>Upload 360 Videos</Typography>
                                )}
                            </VTButton>
                        </Grid>
                    )}
                </Grid>
                {/*-------------------------------------------------------------------------------------*/}
                {/* Card display */}

                {activeButton === 0 && (
                    <>
                        {immersiveVideos && immersiveVideos.length === 0 ? (
                            <Grid item xs={12} textAlign={"center"} sx={{ marginTop: "50px" }}>
                                <img src={NoVideos} />
                                <VTTypography sx={{ color: "grey", padding: "10px" }}>
                                    To get started, add first 360 video file (.mp4 format)
                                </VTTypography>
                            </Grid>
                        ) : loading ? (
                            <Grid
                                sx={{}}
                                item
                                container
                                justifyContent="flex-start"
                                alignItems="flex-start"
                                spacing={1}
                            >
                                <Grid item sm={12} md={6} lg={4} xl={3} xs={12}>
                                    <VTCard
                                        sx={{ maxWidth: "100%" }}
                                        mediaType={{ imageUrl: "" }}
                                        title={""}
                                        loading={true}
                                    />
                                </Grid>
                                <Grid item sm={12} md={6} lg={4} xl={3} xs={12}>
                                    <VTCard
                                        sx={{ maxWidth: "100%" }}
                                        mediaType={{ imageUrl: "" }}
                                        title={""}
                                        loading={true}
                                    />
                                </Grid>
                                <Grid item sm={12} md={6} lg={4} xl={3} xs={12}>
                                    <VTCard
                                        sx={{ maxWidth: "100%" }}
                                        mediaType={{ imageUrl: "" }}
                                        title={""}
                                        loading={true}
                                    />
                                </Grid>

                                {(currentScreenWidthBreakpoint === "xl" || currentScreenWidthBreakpoint === "md") && (
                                    <Grid item sm={12} md={6} lg={4} xl={3} xs={12}>
                                        <VTCard
                                            sx={{ maxWidth: "100%" }}
                                            mediaType={{ imageUrl: "" }}
                                            title={""}
                                            loading={true}
                                        />
                                    </Grid>
                                )}
                            </Grid>
                        ) : (
                            <Grid
                                sx={{}}
                                item
                                container
                                justifyContent="flex-start"
                                alignItems="flex-start"
                                spacing={1}
                            >
                                {immersiveVideos &&
                                    immersiveVideos
                                        ?.filter((immersiveVideo: IMediaItem) => {
                                            return (
                                                (nameBoxChecked &&
                                                    immersiveVideo.name
                                                        ?.toLowerCase()
                                                        .includes(filterValue.toLowerCase())) ||
                                                (tagsBoxChecked &&
                                                    immersiveVideo.tags &&
                                                    immersiveVideo.tags.filter((tag: string) => {
                                                        return tag.toLowerCase().includes(filterValue.toLowerCase());
                                                    }).length > 0)
                                            );
                                        })
                                        .sort((a: IMediaItem, b: IMediaItem) => {
                                            return new Date(b.date).valueOf() - new Date(a.date).valueOf();
                                        })
                                        .map((immersiveVideo: IMediaItem, i: number) => (
                                            <Grid
                                                key={immersiveVideo.id}
                                                sm={12}
                                                md={6}
                                                lg={4}
                                                xl={3}
                                                xs={12}
                                                item
                                                container
                                                justifyContent={"center"}
                                            >
                                                {(deleteLoading || immersiveVideoUpdateLoading) &&
                                                changedItem === immersiveVideo.id ? (
                                                    <VTCard
                                                        sx={{ maxWidth: "100%", width: "100%" }}
                                                        mediaType={{ imageUrl: "" }}
                                                        title={""}
                                                        loading={true}
                                                    />
                                                ) : (
                                                    <VTCard
                                                        ref={i === 0 ? vtCardRef : undefined}
                                                        imageIsBeingProcessedMsg={
                                                            //@ts-ignore
                                                            immersiveVideo.chopping === "processing"
                                                                ? "360 image is being processed, please wait and refresh the page in a minute or so"
                                                                : undefined
                                                        }
                                                        imageProcessingErrorMsg={
                                                            //@ts-ignore
                                                            immersiveVideo.chopping === "error"
                                                                ? "there was an error processing this 360 image, please delete and try again or contact virtus tech support"
                                                                : undefined
                                                        }
                                                        titleCutOffPoint={titleCutOffPoint}
                                                        onTitleEdited={(editedTitle: string) => {
                                                            if (whiteListedIds.includes(immersiveVideo.id)) {
                                                                dispatch(
                                                                    setCurrentToast({
                                                                        id: "EditDefaultMedia",
                                                                        message:
                                                                            "You can not edit default media. Please upload your own media.",
                                                                    }),
                                                                );
                                                            } else {
                                                                setChangedItem(immersiveVideo.id);
                                                                updateAllImmersiveVideos({
                                                                    id: immersiveVideo.id,
                                                                    name: editedTitle,
                                                                    media_type: EDraggableOptions.IMMERSIVE_VIDEO,
                                                                });
                                                            }
                                                        }}
                                                        key={immersiveVideo.id}
                                                        sx={{ width: "100%", maxWidth: "none" }}
                                                        title={immersiveVideo.name ? immersiveVideo.name : "no name"}
                                                        mediaType={{
                                                            showPreview: true,
                                                            preview: `${process.env.REACT_APP_NINJA_MEDIA}/${organisation}/three_sixty_video/${immersiveVideo.id}/${immersiveVideo.id}_preview.jpg`,
                                                            videoUrl: `${process.env.REACT_APP_NINJA_MEDIA}/${organisation}/three_sixty_video/${immersiveVideo.id}/${immersiveVideo.id}.${immersiveVideo.filetype}`,
                                                        }}
                                                        onWholeCardClicked={() => {}}
                                                        menuOptions={{
                                                            menuOptionsList: [
                                                                // {
                                                                //     component: (
                                                                //         <VTButton
                                                                //             type={"list"}
                                                                //             startIcon={<MyLocationIcon />}
                                                                //             disabled={true}
                                                                //             onClick={() => {}}
                                                                //         >
                                                                //             Set start location
                                                                //         </VTButton>
                                                                //     ),
                                                                // },
                                                                {
                                                                    component: (
                                                                        <>
                                                                            {organisation === "virtus-tech" && (
                                                                                <VTButton
                                                                                    type={"list"}
                                                                                    startIcon={<DownloadIcon />}
                                                                                    onClick={() => {
                                                                                        const imageUrl = `${process.env.REACT_APP_NINJA_MEDIA}/${organisation}/three_sixty_video/${immersiveVideo.id}/${immersiveVideo.id}.${immersiveVideo.filetype}`;

                                                                                        const link =
                                                                                            document.createElement("a");
                                                                                        link.href = imageUrl;

                                                                                        document.body.appendChild(link);
                                                                                        link.click();

                                                                                        document.body.removeChild(link);
                                                                                    }}
                                                                                >
                                                                                    Download
                                                                                </VTButton>
                                                                            )}
                                                                        </>
                                                                    ),
                                                                },
                                                                {
                                                                    component: (
                                                                        <VTButton
                                                                            type={"list"}
                                                                            startIcon={<DeleteIcon />}
                                                                            disabled={false}
                                                                            onClick={() => {
                                                                                setChangedItem(immersiveVideo.id);
                                                                                deleteMediaItem(
                                                                                    EDraggableOptions.IMMERSIVE_VIDEO,
                                                                                    userId,
                                                                                    immersiveVideo.id,
                                                                                );
                                                                            }}
                                                                        >
                                                                            Delete
                                                                        </VTButton>
                                                                    ),
                                                                },
                                                            ],
                                                            menuPopOutZIndex: 100,
                                                        }}
                                                    />
                                                )}
                                            </Grid>
                                        ))}
                            </Grid>
                        )}
                    </>
                )}

                {/*-----------------------------------------------------------------------------------------*/}
                {/* Video upload */}
                {activeButton === 1 && (
                    // <VTImmersiveVideoUpload onComplete={() => setActiveButton(0)} sx={{ marginTop: "-50px" }} />
                    <VTMediaUploader
                        onComplete={() => setActiveButton(0)}
                        sx={{ marginTop: "-50px" }}
                        context={"Immersive Videos"}
                    />
                )}
            </Grid>
        </Grid>
    );
}
